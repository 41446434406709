import { AxiosError } from 'axios';
import { INotification } from '@insly/qmt-reactjs-ui-lib/dist/components/Notification';
import { processAPIError } from './processAPIError';

export const errorNotification = (error: AxiosError, showNotification: (props: Partial<INotification>) => void, autoHide: boolean = false) => {

  let errorData = {
    message: processAPIError(error) as string,
    preset: 'error',
    autoHide: autoHide
  };

  showNotification(errorData);

};
