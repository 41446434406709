import { TStandardSectionStep } from 'models';
import { AxiosError } from 'axios';
import { useApp } from 'context/App';
import { errorNotification, translate } from 'utils';
import {
  DrawerControlsRow,
  DrawerControlsRowControl,
  DrawerControlsRowControlIcon, DrawerControlsRowControlLabel
} from './styles';
import { DefaultIcon } from 'styles/common';
import React from 'react';

const ControlsRow = ({
  step,
  updateStep,
  onUserDelete,
  userId,
  userAdditionalControl,
  defaultProfileStep = 'summary',
}: {
  step: TStandardSectionStep,
  updateStep: (step: string) => void,
  onUserDelete: (userId: string, onError: (error: AxiosError) => void) => void,
  userId: string,
  userAdditionalControl?: JSX.Element,
  defaultProfileStep?: string,
}) => {

  const { showNotification, showDialog } = useApp();

  const onError = (error: AxiosError) => {
    errorNotification(error, showNotification, true);
  };

  return (
    <DrawerControlsRow>
      {step === 'profile' ? (
        <DrawerControlsRowControl onClick={() => {
          showDialog({
            body: translate({ key: 'master.dialog.remove_customer' }),
            buttonAccept: <>
              {translate({ key: 'master.dialog.button.remove_customer' })}&nbsp;
              <DefaultIcon icon="trash" />
            </>,
            buttonDecline: translate({ key: 'common.button.cancel' }),
            onAccept: () => onUserDelete(userId, onError),
          });
        }}>
          <DrawerControlsRowControlIcon margin="right" icon="remove" />
          <DrawerControlsRowControlLabel>{translate({ key: 'master.remove_profile' })}</DrawerControlsRowControlLabel>
        </DrawerControlsRowControl>
      ) : (
        <DrawerControlsRowControl onClick={() => updateStep(`profile/${userId}/${defaultProfileStep}`)}>
          <DrawerControlsRowControlIcon margin="right" icon="profile_card" />
          <DrawerControlsRowControlLabel>{translate({ key: 'master.open_profile' })}</DrawerControlsRowControlLabel>
        </DrawerControlsRowControl>
      )}
      {userAdditionalControl}
    </DrawerControlsRow>
  );
};

export default ControlsRow;
