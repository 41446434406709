import { getCookie } from './cookie';

export const getAPIToken = () => {
  if (getCookie('authentication_result')) {
    const authData = JSON.parse(getCookie('authentication_result') as string);
    return `${authData.token_type} ${authData.access_token}`;
  }

  return '';
};
