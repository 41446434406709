import { TUser } from 'app/Pages/common';
import React, { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosPromise } from 'axios';
import { TError, TFetchStatus } from 'models';
import { DrawerDataForm } from 'app/Components/common/Drawer/styles';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { renderErrors } from 'app/Components/common/Error';
import apiConfig from 'config/api';
import { Loader } from 'styles/common';
import { cloneObject, concurrentRequestsResolver, preflightAPICall, processAPIError, translate } from 'utils';
import { TabsStyled } from 'app/Pages/common/Profile/styles';
import { generateTabs } from 'app/Pages/User/profile/index';
import InsurersList from 'app/Components/common/InsurersList';

const API_PRODUCT_GROUPS = `${apiConfig.MASTER}/product-groups`;
const API_USER_INSURERS = `${apiConfig.MASTER}/users/%user_id%/insurers`;

interface IProps {
  profile: TUser,
  onSaveData: (data: TUser) => void,
}

export type TInsurer = {
  id: string,
  insurer_id: string,
  tag: string,
  title: string,
  product_group_id: string,
  has_issuing: boolean,
  props: {
    payment_methods?: string[],
  },
};

type TProductGroup = {
  id: string,
  title: string,
  tag: string,
};

const Insurers = ({ profile, onSaveData }: IProps) => {

  const [dataFetchStatus, updateDataFetchStatus] = useState<TFetchStatus>(null);
  const [userInsurersList, updateUserInsurersList] = useState<TInsurer[]>([]);
  const [productGroupId, updateProductGroupId] = useState('');
  const [errors, updateErrors] = useState<TError[]>([]);
  const [isSubmitting, updateIsSubmitting] = useState(false);

  const [productTag, updateProductTag] = useState('');

  const TABS = [
    {
      key: 'motor',
      title: translate({ key: 'common.quote.type.motor' }),
    },
    {
      key: 'travel',
      title: translate({ key: 'common.quote.type.travel' }),
    },
    {
      key: 'border',
      title: translate({ key: 'common.quote.type.border' }),
    },
    {
      key: 'assistance',
      title: 'Assistance',
    },
  ];

  useEffect(() => {
    updateDataFetchStatus(null);
    updateProductTag('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  useEffect(() => {
    if (productTag) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTag]);

  const onError = (error: AxiosError) => {
    updateErrors(() => processAPIError(error, true) as TError[]);

    if (isSubmitting) {
      updateIsSubmitting(false);
    }

    if (dataFetchStatus === 'loading') {
      updateDataFetchStatus('failed');
    }

    return false;
  };

  const getData = () => {
    updateDataFetchStatus('loading');
    preflightAPICall(() => {
      const dataRequests: AxiosPromise[] = [];

      //Get User Insurers List
      dataRequests.push(axios.get(`${API_USER_INSURERS.replace('%user_id%', profile.id as string)}?group_tag=${productTag}`));

      //Get Product Groups List
      dataRequests.push(axios.get(API_PRODUCT_GROUPS));

      const dataRequestsResolved = concurrentRequestsResolver(dataRequests);

      if (dataRequestsResolved.length > 0) {
        axios.all(dataRequestsResolved).then(axios.spread((userInsurers, products) => {
          // @ts-ignore need to investigate how to handle such different types of response
          if (products.error) {
            const response = products as { error: Error };
            if (onError) {
              onError(response.error as AxiosError);
            }
            // @ts-ignore need to investigate how to handle such different types of response
          } else if (userInsurers.error) {
            const response = userInsurers as { error: Error };
            if (onError) {
              onError(response.error as AxiosError);
            }
          } else {
            // @ts-ignore need to investigate how to handle such different types of response
            updateUserInsurersList(userInsurers.data);

            // @ts-ignore need to investigate how to handle such different types of response
            updateProductGroupId(getProductId(products.data, productTag));

            updateDataFetchStatus('success');
          }

        })).catch(onError);
      }
    });
  };

  const handlePatchUserInsurerParams = async(key: string, value: boolean) => {
    updateIsSubmitting(true);
    try {
      return await preflightAPICall(async() => {
        try {
          return await axios.patch(`${API_USER_INSURERS.replace('%user_id%', profile.id as string)}/${key}`, {
            has_issuing: value,
          }).then(() => {
            updateIsSubmitting(false);
            return true;
          });
        } catch (error) {
          onError(error as AxiosError);
          return false;
        }
      });
    } catch (error) {
      onError(error as AxiosError);
      return true;
    }
  };

  const handleUserInsurer = async(insurerId: string, add: boolean) => {
    updateIsSubmitting(true);

    try {
      return await preflightAPICall(async() => {
        try {
          if (add) {
            return await axios.post(`${API_USER_INSURERS.replace('%user_id%', profile.id as string)}`, {
              insurer_id: insurerId,
              product_group_id: productGroupId,
              has_issuing: true
            }).then((response) => {
              updateIsSubmitting(false);
              return (response.data);
            }).catch(onError);
          } else {
            return await axios.delete(`${API_USER_INSURERS.replace('%user_id%', profile.id as string)}/${insurerId}`).then(() => {
              updateIsSubmitting(false);
              return insurerId;
            }).catch(onError);
          }
        } catch (error) {
          onError(error as AxiosError);
          return false;
        }
      });
    } catch (error) {
      onError(error as AxiosError);
      return true;
    }
  };

  return (
    <>
      <TabsStyled
        items={generateTabs(TABS, productTag, updateProductTag)}
      />
      <Body
        userData={profile}
        userInsurersList={userInsurersList}
        dataFetchStatus={dataFetchStatus}
        handleUserInsurer={handleUserInsurer}
        handlePatchUserInsurerParams={handlePatchUserInsurerParams}
        isSubmitting={isSubmitting}
        errors={errors}
        updateErrors={updateErrors}
        onSaveData={onSaveData}
        productTag={productTag}
      />
    </>
  );
};

interface IBody {
  userData: TUser,
  dataFetchStatus: TFetchStatus,
  userInsurersList: TInsurer[],
  handleUserInsurer: (insurerId: string, add: boolean) => Promise<unknown>,
  handlePatchUserInsurerParams: (key: string, value: boolean) => Promise<unknown>,
  isSubmitting: boolean,
  errors: TError[],
  updateErrors: (errors: TError[]) => void,
  onSaveData: (data: TUser) => void,
  productTag?: string,
}

const Body = ({
  userData,
  userInsurersList,
  dataFetchStatus,
  handleUserInsurer,
  handlePatchUserInsurerParams,
  isSubmitting,
  errors,
  updateErrors,
  onSaveData,
  productTag
}: IBody) => {
  switch (dataFetchStatus) {
    case 'success':
      return (
        <InsurersForm
          userData={userData}
          userInsurersList={userInsurersList}
          handleUserInsurer={handleUserInsurer}
          handlePatchUserInsurerParams={handlePatchUserInsurerParams}
          isSubmitting={isSubmitting}
          errors={errors}
          updateErrors={updateErrors}
          onSaveData={onSaveData}
          productTag={productTag}
        />
      );
    case 'failed':
      return (
        <>
          {renderErrors(errors)}
        </>
      );
    case 'loading':
      return <Loader />;
    default:
      return <div />;
  }
};

interface IInsurersForm {
  userData: TUser,
  userInsurersList: TInsurer[],
  handleUserInsurer: (insurerId: string, add: boolean) => Promise<unknown>,
  handlePatchUserInsurerParams: (key: string, value: boolean) => Promise<unknown>,
  isSubmitting: boolean,
  errors: TError[],
  updateErrors: (errors: TError[]) => void,
  onSaveData: (data: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  productTag?: string,
}

const InsurersForm = ({
  userData,
  userInsurersList,
  handleUserInsurer,
  handlePatchUserInsurerParams,
  isSubmitting,
  errors,
  updateErrors,
  onSaveData,
  productTag
}: IInsurersForm) => {

  const [form, updateForm] = useState(userInsurersList ? [...userInsurersList] : []);

  const handleChangeInsurer = async(insurerId: string, value: boolean) => {
    const selectedInsurers = [...form];

    const insurerData = await handleUserInsurer(insurerId, value);

    if (insurerData) {
      if (value) {
        selectedInsurers.push(insurerData as TInsurer);
      } else {
        const insurerIndex = selectedInsurers.findIndex(i => i.id === insurerId);
        selectedInsurers.splice(insurerIndex, 1);
      }
      updateForm(selectedInsurers);
    }
  };

  const handleChangeIssuing = async(key: string, value: boolean) => {
    const insurerData = await handlePatchUserInsurerParams(key, value);
    if (insurerData) {

      const selectedInsurers = [...form];
      const insurerIndex = selectedInsurers.findIndex(i => i.id === key);

      if (insurerIndex !== -1) {
        selectedInsurers[insurerIndex] = {
          ...selectedInsurers[insurerIndex],
          has_issuing: value
        };
      }

      if (errors.length) {
        updateErrors([]);
      }
      updateForm(selectedInsurers);
    }
  };

  const onSubmitPaymentMethods = (paymentMethods: Record<string, string[]>, onError: (errors: AxiosError) => void, onSuccess: () => void) => {
    const dataToSave = cloneObject(userData);
    dataToSave.props.payment_methods = paymentMethods;
    onSaveData(dataToSave, onError, onSuccess);
  };

  return (
    <DrawerDataForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      <InsurersList
        isEditable={true}
        onSubmitPaymentMethods={onSubmitPaymentMethods}
        form={form}
        handleChangeInsurer={handleChangeInsurer}
        handleChangeIssuing={handleChangeIssuing}
        paymentMethodsToProcess={userData.props.payment_methods}
        productTag={productTag}
        allowedPaymentMethods={userData.broker?.props.payment_methods}
      />
      {errors?.length ? renderErrors(errors) : null}
    </DrawerDataForm>
  );
};

const getProductId = (products: TProductGroup[], productTag: string) => products.find(item => item.tag === productTag)?.id || '';

export default Insurers;
