import React from 'react';
import { ShowMoreStyled, ShowMoreText, ShowMoreIcon } from './styles';

export const SHOW_MORE_EL_HEIGHT = 64;

export const shouldShowMoreShow = (element: HTMLDivElement, showMore: boolean, updateShowMore: (showMore: boolean) => void) => {
  const showShowMore = element.scrollHeight - element.scrollTop > (element.clientHeight + SHOW_MORE_EL_HEIGHT);
  if (showMore !== showShowMore) {
    updateShowMore(showShowMore);
  }
};

export const ShowMore = ({ show, onClick }: {show: boolean, onClick: () => void}) => (
  <ShowMoreStyled show={show} onClick={onClick}>
    <ShowMoreText>Zobacz więcej</ShowMoreText>
    <ShowMoreIcon icon="arrow_down" />
  </ShowMoreStyled>
);
