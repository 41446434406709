import styled from 'styled-components';
import { Button, LoaderAnimated, SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const Loader = styled(LoaderAnimated)`
  width: 32px;
  height: 32px;
  margin: auto;
`;

export const Input = styled.input`
  border: 0;
  outline: none;
  padding: 0;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  ${({ theme }) => theme.text.inputValue};
  /* clears the ‘X’ from Internet Explorer */
  &[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  &[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the ‘X’ from Chrome */
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration { display: none; }
`;

export const Drop = styled.ul`
`;

export const DropItem = styled.li`
  ${({ theme }) => theme.text.normal};
  transition: background-color 0.3s ease;
  padding: 16px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
  &:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
  }
  &:last-child {
    border-radius: 0 0 ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverGray};
  }
`;

export const LoadingOverlayStyled = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const DefaultIcon = styled(SvgIcon)`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`;

export const ControlsIcon = styled(DefaultIcon)`
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const ContactsItem = styled.div`
  flex: 1;
  .qmt-ui-input.address-house-nr {
    position: relative;
    &:after {
      content: '/';
      position: absolute;
      right: 0;
      bottom: 18px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.textSecondary};
    }
  }
`;

export const ContactPersonRow = styled.div<{ gridTemplate: string }>`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ContactsDataBlockTitle = styled.h3`
  ${({ theme }) => theme.text.headerH3};
  color: #282828;
  margin-bottom: 40px;
`;

export const ContactsDataBlockText = styled.h3`
  ${({ theme }) => theme.text.headerH3};
`;

export const ButtonStandard = styled(Button)`
  width: fit-content;
  height: 40px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;
