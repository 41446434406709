import { Profile, ProfileInner } from 'app/Pages/common/styles';
import React from 'react';
import { TProfileTab } from '../index';
import { ProfileBodyStyled, TabsStyled } from 'app/Pages/common/Profile/styles';
import { Loader } from 'styles/common';
import { TError, TStandardSectionStep } from 'models';
import { renderErrors } from 'app/Components/common/Error';
import { TUser } from 'app/Pages/common';
import Summary from './body/Summary';
import { AxiosError } from 'axios';
import Insurers from 'app/Pages/User/profile/body/Insurers';
import Documents from 'app/Pages/common/Documents';
import { translate } from 'utils';
import { GDPR_COMMON_DOC_TYPE, IDD_COMMON_DOC_TYPE } from 'config/consts';

interface IProps {
  step: TStandardSectionStep,
  updateStep: (section: string) => void,
  activeTab?: TProfileTab,
  userId?: string,
  profile: TUser | null,
  dataStatus: string,
  userErrors: TError[],
  handlePatchUser: (selectedUser: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  updateSelectedUser: (userData: TUser) => void,
}

export const UserProfile = ({
  step,
  updateStep,
  activeTab = 'summary',
  userId,
  profile,
  handlePatchUser,
  updateSelectedUser,
  dataStatus,
  userErrors
}: IProps) => {
  const TABS = [
    {
      key: 'summary',
      title: translate({ key: 'master.user.summary.title' }),
    },
    {
      key: 'insurers',
      title: translate({ key: 'master.user.insurers.title' }),
    },
    {
      key: 'documents',
      title: translate({ key: 'master.documents.title' }),
    },
    {
      key: 'gdpr_documents',
      title: translate({ key: 'master.gdpr_documents.title' }),
    },
  ];

  const updateActiveTab = (tab: TProfileTab) => {
    if (tab !== activeTab) {
      updateStep(`user/profile/${userId}/${tab}`);
    }
  };

  const onSaveUser = (user: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => {
    profile && handlePatchUser({
      ...user
    }, onError, onSuccess);
  };

  return (
    <Profile step={step}>
      <ProfileInner>
        <TabsStyled
          items={generateTabs(TABS, activeTab, updateActiveTab)}
        />
        <ProfileBodyStyled>
          <ProfileBody
            activeTab={activeTab}
            profile={profile}
            onSaveUser={onSaveUser}
            updateUser={updateSelectedUser}
            dataStatus={dataStatus}
            userErrors={userErrors}
            step={step}
          />
        </ProfileBodyStyled>
      </ProfileInner>
    </Profile>
  );
};

interface IProfileBody {
  activeTab: string,
  profile: TUser | null,
  onSaveUser: (user: TUser) => void,
  updateUser: (user: TUser) => void,
  dataStatus: string,
  userErrors: TError[],
  step: TStandardSectionStep,
}

const ProfileBody = ({
  activeTab,
  profile,
  onSaveUser,
  updateUser,
  dataStatus,
  userErrors,
  step,
}: IProfileBody) => {

  switch (dataStatus) {
    case 'error':
      return (
        <>
          {renderErrors(userErrors)}
        </>
      );
    case 'success':
      if (profile) {
        switch (activeTab) {
          case 'summary':
            return <Summary
              profile={profile}
              handlePatchUser={onSaveUser}
              updateUserProfile={updateUser}
              step={step}
            />;
          case 'insurers':
            return <Insurers profile={profile} onSaveData={onSaveUser} />;
          case 'documents':
            return <Documents refId={profile.id as string} docType={IDD_COMMON_DOC_TYPE} userDomain={profile.role?.domain} />;
          case 'gdpr_documents':
            return <Documents refId={profile.id as string} docType={GDPR_COMMON_DOC_TYPE} userDomain={profile.role?.domain} />;
        }
      }
      break;
    case 'loading':
    default:
      return <Loader />;
  }

  return <div />;
};

export const generateTabs = (tabs: {key: string, title: string}[], activeTab: string, updateActiveTab: (tab: TProfileTab) => void) => tabs.map((item) => ({
  name: item.key,
  title: item.title,
  active: activeTab === item.key,
  onChange: () => updateActiveTab(item.key as TProfileTab)
}));
