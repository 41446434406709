import axios from 'axios';
import { logout } from './logout';

interface IOptions {
  authHeader: string,
}

export const configAxios = (options: IOptions) => {
  axios.defaults.headers.common['Authorization'] = options.authHeader;

  axios.interceptors.response.use(response => response, error => {
    if (error.response?.status === 401) {
      logout();
    }

    return Promise.reject(error);
  });
};
