
const apiConfig = {
  IDENTIFIER: window._env_.API_IDENTIFIER_URL,
  MASTER: `${window._env_.API_MASTER_SERVICE_URL}/t/${window._env_.TENANT}/admin`,
  MASTER_DOCUMENT: `${window._env_.API_MASTER_SERVICE_URL}/t/${window._env_.TENANT}/documents`,
  MASTER_PUBLIC: `${window._env_.API_MASTER_SERVICE_URL}/t/${window._env_.TENANT}`,
  SITE_SERVICE: window._env_.API_SITE_SERVICE_URL,
  TENANT: window._env_.TENANT,
  CALCLY: window._env_.API_CALCLY_URL,
  DOCUMENT: `${window._env_.API_DOC_SERVICE_URL}/t/${window._env_.TENANT}/documents`,
};

export const API_USER_LIST_URL = `${apiConfig.MASTER}/users`;
export const API_ADMIN_LIST_URL = `${apiConfig.MASTER_PUBLIC}/admins`;
export const API_GROUP_LIST_URL = `${apiConfig.MASTER}/groups`;

export const API_GET_ALL_INSURERS = `${apiConfig.MASTER}/insurers`;

export const DEFAULT_LOCALE = window._env_.DEFAULT_LOCALE;

export default apiConfig;
