import { renderErrors } from 'app/Components/common/Error';
import { Loader } from 'styles/common';
import React, { useEffect, useState } from 'react';
import Insurer from 'app/Components/common/Insurer';
import { TError, TFetchStatus } from 'models';
import { TInsurer } from 'app/Pages/User/profile/body/Insurers';
import { changePaymentMethods } from 'app/Components/common/InsurersList/utils/changePaymentMethods';
import axios, { AxiosError } from 'axios';
import { cloneObject, errorNotification, preflightAPICall, processAPIError } from 'utils';
import { useApp } from 'context/App';
import { API_GET_ALL_INSURERS } from 'config/api';

export const API_GET_ALL_ORDERED_INSURERS = `${API_GET_ALL_INSURERS}?order_by[tag]=ASC`;

interface IInsurersList {
  isEditable: boolean,
  onSubmitPaymentMethods: (paymentMethods: Record<string, string[]>, onError: (errors: AxiosError) => void, onSuccess: () => void) => void,
  form?: TInsurer[],
  handleChangeInsurer?: (insurerId: string, value: boolean) => void,
  handleChangeIssuing?: (key: string, value: boolean) => void,
  paymentMethodsToProcess: Record<string, string[]> | null | undefined,
  productTag?: string,
  allowedPaymentMethods?: Record<string, string[]>,
}

const InsurersList = ({
  isEditable,
  onSubmitPaymentMethods,
  form,
  handleChangeInsurer,
  handleChangeIssuing,
  paymentMethodsToProcess,
  productTag,
  allowedPaymentMethods
}: IInsurersList) => {
  const { showNotification } = useApp();

  const [fetchInsurersStatus, updateFetchInsurersStatus] = useState<TFetchStatus>(null);
  const [paymentMethodsFetchStatus, updatePaymentMethodsFetchStatus] = useState<TFetchStatus>(null);
  const [errors, updateErrors] = useState<TError[]>([]);
  const [insurersList, updateInsurersList] = useState<TInsurer[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<Record<string, string[]>>({});

  const onChangePaymentMethods = (method: string, value: boolean, tag: string, defaultPaymentMethods: string[]) =>
    setPaymentMethods(changePaymentMethods(method, value, tag, paymentMethods, defaultPaymentMethods));

  const onError = (error: AxiosError) => {
    updatePaymentMethodsFetchStatus('failed');
    errorNotification(error, showNotification);
  };

  const onSuccess = () => updatePaymentMethodsFetchStatus('success');

  const prepareDataBeforeSend = () => {
    updatePaymentMethodsFetchStatus('loading');
    Object.keys(paymentMethods).forEach(tag => !paymentMethods[tag].length && delete paymentMethods[tag]);
    onSubmitPaymentMethods(paymentMethods, onError, onSuccess);
  };

  const handleError = (error: AxiosError) => {
    updateErrors(() => processAPIError(error, true) as TError[]);

    if (fetchInsurersStatus === 'loading') {
      updateFetchInsurersStatus('failed');
    }
  };

  const url = isEditable ? `${API_GET_ALL_ORDERED_INSURERS}&product_tag=${productTag}` : API_GET_ALL_ORDERED_INSURERS;

  const getAllInsurers = () => {
    updateFetchInsurersStatus('loading');
    preflightAPICall(() => {
      axios.get(url)
        .then(response => {
          updateInsurersList(response.data.results);
          updateFetchInsurersStatus('success');
        }).catch(handleError);
    });
  };

  useEffect(() => {
    paymentMethodsToProcess && setPaymentMethods(cloneObject(paymentMethodsToProcess));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllInsurers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInsurers = () => insurersList.map((i) => {
    const formItem = form && form.find(insurer => insurer.insurer_id === i.id);

    return <Insurer
      key={i.id}
      insurer={i}
      userInsurerId={isEditable ? formItem?.id : undefined}
      isIssuingEnabled={isEditable ? formItem ? formItem.has_issuing : false : undefined}
      isInsurerEnabled={isEditable ? !!formItem : undefined}
      handleChangeInsurer={isEditable ? handleChangeInsurer : undefined}
      handleChangeIssuing={isEditable ? handleChangeIssuing : undefined}
      onSubmitData={prepareDataBeforeSend}
      onChangePaymentMethods={onChangePaymentMethods}
      isEditable={isEditable}
      dataFetchStatus={paymentMethodsFetchStatus}
      paymentMethods={paymentMethods}
      defaultPaymentMethods={
        allowedPaymentMethods && allowedPaymentMethods[i.tag] && allowedPaymentMethods[i.tag].length
          ? allowedPaymentMethods[i.tag] : i.props?.payment_methods
      }
    />;
  });

  switch (fetchInsurersStatus) {
    case 'failed':
      return <>{renderErrors(errors)}</>;
    case 'success':
      return <>{renderInsurers()}</>;
    case 'loading':
    default:
      return <Loader />;
  }
};

export default InsurersList;
