import styled from 'styled-components';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const DocumentsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const UploaderDocumentsString = styled.div`
  ${({ theme }) => theme.text.normal};
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const UploaderDocuments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px;
  background-color: #F0F0F0;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  &:hover {
    background-color: #E8E8E8;
  }
  &.drag-over {
    background-color: ${({ theme }) => theme.colors.primary};
    ${UploaderDocumentsString} {
      border-right: none;
      color: ${({ theme }) => theme.colors.white};
      &:last-child {
        display: none;
      }
    }
  }
`;

export const UploaderDocumentsIcon = styled(SvgIcon)<{ margin?: string }>`
  width: 24px;
  height: 24px;
  stroke: currentColor;
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null};
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
`;

export const UploaderInput = styled.input`
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  display: block;
  font-size: 0;
  line-height: 0;
`;

export const Progress = styled.div`
  position: relative;
  width: 100px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.strokeB};
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressPercents = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

export const ProgressBar = styled.span`
  position: absolute;
  display: inline-block;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const DocError = styled.div`
  display: flex;
  align-items: center;
`;

export const DocErrorText = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 10px;
`;

export const DocErrorDeleteIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  stroke: ${({ theme }) => theme.colors.primary};
`;
