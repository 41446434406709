import { errorNotification, preflightAPICall, translate } from 'utils';
import {
  ControlsIconWrapper,
  ControlsSet,
  DocError,
  DocErrorDeleteIcon,
  DocErrorText,
  DocumentControlsCol,
  DocumentFormat,
  DocumentIcon,
  DocumentName,
  DocumentNameCol,
  DocumentsListStyled,
  Progress,
  ProgressBar,
  ProgressPercents,
  ProgressWrapper,
} from './styles';
import React from 'react';
import { TDoc, TError } from 'models';
import apiConfig, { DEFAULT_LOCALE } from 'config/api';
import { useApp } from 'context/App';
import axios from 'axios';
import { ControlsIcon, DefaultIcon } from 'styles/common';

export interface IDocumentsList {
  refId: string,
  noResultsText: string,
  data: TDoc[],
  dataStatus: string,
  handleRemoveDoc: (id: string) => void,
}

const DocumentsList = ({
  refId,
  noResultsText = translate({ key: 'common.no_results' }),
  data,
  dataStatus,
  handleRemoveDoc
}: IDocumentsList) => <DocumentsListStyled
  showHeadline={true}
  columns={[
    {
      title: 'Name',
      name: 'name',
      onRender: renderDocumentName,
      styles: {
        flex: 2
      }
    },
    {
      name: 'info',
      onRender: (rowData) => renderDocumentInfo({
        data: rowData,
        handleRemoveDoc
      }),
    },
    {
      title: 'Type',
      name: 'type',
      onRender: renderDocumentType,
      styles: {
        flex: 1
      }
    },
    {
      title: 'Add date',
      name: 'created_at',
      onRender: renderCreatedAt,
      styles: {
        width: '100px'
      }
    },
    {
      title: 'Version',
      name: 'version',
      styles: {
        width: '50px'
      }
    },
    {
      name: 'controls',
      title: '',
      onRender: (rowData) => renderDocumentControls({
        data: rowData,
        refId: refId as string,
        handleRemoveDoc
      }),
      styles: {
        width: '30px'
      }
    }
  ]}
  dataStatus={dataStatus}
  data={data as never[]}
  noResultsText={noResultsText || translate({ key: 'common.no_results' })}
  hideSearch={true}
/>;

const renderDocumentControls = ({ data, refId, handleRemoveDoc }: { data: TDoc, refId: string, handleRemoveDoc: (id: string) => void }) => {
  if (typeof data.progress === 'number') {
    return renderProgress(data.progress);
  } else if (data.errors) {
    return renderErrors(data, handleRemoveDoc);
  } else {
    return <DocumentControls
      data={data}
      customerId={refId}
      handleRemoveDoc={handleRemoveDoc}
    />;
  }
};

const DocumentControls = ({ data, customerId, handleRemoveDoc }: { data: TDoc, customerId: string, handleRemoveDoc: (id: string) => void }) => {

  const { showDialog, showNotification } = useApp();

  const handleRemoveDialog = () => {
    showDialog({
      body: translate({ key: 'dialog.remove_document.body' }),
      buttonAccept: <>
        {translate({ key: 'dialog.remove_document.accept' })}&nbsp;
        <DefaultIcon icon="trash" />
      </>,
      buttonDecline: translate({ key: 'common.cancel' }),
      onAccept: handleRemove,
    });
  };

  const handleRemove = () => {
    preflightAPICall(() => {
      axios.delete(`${apiConfig.DOCUMENT}/${customerId}/${data.id}`).then(response => {
        if (response.status === 204) {
          handleRemoveDoc(data.id as string);
        }
      }).catch(error => {
        errorNotification(error, showNotification);
      });
    });
  };

  let controls = [
    // {
    //   icon: 'download',
    //   onClick: () => {
    //     switch (data.download_method) {
    //       case 'GET':
    //         getFile({
    //           url: data.download_path as string,
    //           fileName:  `${data.file_name}`,
    //           onError: (error: AxiosError) => errorNotification(error, showNotification)
    //         });
    //     }
    //   }
    // },
    {
      icon: 'trash',
      onClick: handleRemoveDialog
    }
  ];

  return (
    <DocumentControlsCol>
      {renderControlsSet(controls)}
    </DocumentControlsCol>
  );
};

const renderControlsSet = (controls: {icon: string, onClick: () => void}[]) => (
  <ControlsSet>
    {controls.map((item, index) => (
      <ControlsIconWrapper key={index}>
        <ControlsIcon icon={item.icon} onClick={item.onClick} />
      </ControlsIconWrapper>
    ))}
  </ControlsSet>
);

const renderDocumentName = (rowData: TDoc) => (
  <DocumentNameCol error={!!rowData.errors?.length} isUploading={typeof rowData.progress === 'number'}>
    <DocumentIcon icon="file_text" />
    <DocumentName>
      {rowData.title}
      <DocumentFormat>
        {rowData.props?.ext ? `.${rowData.props.ext}` : null}
      </DocumentFormat>
      {/*<DocumentSize>*/}
      {/*  {rowData.props?.size ? decorateBytes(+(rowData.props.size)) : null}*/}
      {/*</DocumentSize>*/}
    </DocumentName>
  </DocumentNameCol>
);

const renderCreatedAt = (rowData: TDoc) => (
  <div>
    {rowData.created_at ? new Date(rowData.created_at).toLocaleDateString(DEFAULT_LOCALE) : ''}
  </div>
);

const renderDocumentType = (rowData: TDoc) => (
  <div>
    {rowData.type ? rowData.type.title : ''}
  </div>
);

const renderDocumentInfo = ({ data, handleRemoveDoc }: { data: TDoc, handleRemoveDoc: (id: string) => void }) => {
  if (typeof data.progress === 'number') {
    return renderProgress(data.progress);
  } else if (data.errors) {
    return renderErrors(data, handleRemoveDoc);
  }
  return <div />;
};

const renderProgress = (progress: number) => (
  <ProgressWrapper>
    <ProgressPercents>
      {Math.round(progress)}%
    </ProgressPercents>
    <Progress>
      <ProgressBar style={ { width: Math.round(progress) + '%' } } />
    </Progress>
  </ProgressWrapper>
);

const renderErrors = (data: TDoc, handleRemoveDoc: (id: string) => void) => (
  <DocError>
    <DocErrorText>
      {data.errors && data.errors.map((item: TError) => `${item.code}: ${item.message} `)}
    </DocErrorText>
    <DocErrorDeleteIcon icon="close" onClick={() => handleRemoveDoc(data.id as string)} />
  </DocError>
);

export default DocumentsList;
