import { JSONSchemaProp } from 'models';
import { AxiosError } from 'axios';
import { useApp } from 'context/App';
import { Dropdown } from '@insly/qmt-reactjs-ui-lib';
import { translateEnumInScheme, errorNotification } from 'utils';
import {
  DropCommonPicker,
  DropCommonPickerContent,
  DropCommonPickerIcon,
  DropCommon,
  DropCommonIcon,
  DropCommonItem,
  DropCommonLabel
} from 'app/Components/common/DropdownSingle/styles';
import React from 'react';
import { DrawerUserTypeLabel, DrawerUserTypeValue } from 'app/Components/common/Drawer/styles';

const DropdownSingle = ({
  vPadding,
  hPadding,
  value,
  onChangeValue,
  schemaProp,
  title
} : {
  vPadding?: string,
  hPadding?: string,
  value: string,
  onChangeValue: (value: string, onError: (error: AxiosError) => void) => void,
  schemaProp: JSONSchemaProp,
  title: string,
}) => {

  const { showNotification } = useApp();

  const onError = (error: AxiosError) => errorNotification(error, showNotification);

  return (
    <Dropdown picker={renderDropdownPicker(translateEnumInScheme(schemaProp, value as string), vPadding, hPadding, title)}>
      <DropCommon>
        {schemaProp.enum?.map((item) => (
          <DropCommonItem key={item} onClick={() => onChangeValue(item, onError)}>
            <DropCommonLabel>
              {translateEnumInScheme(schemaProp, item)}
            </DropCommonLabel>
            {item === value ? (
              <DropCommonIcon icon="check_orange" />
            ) : null}
          </DropCommonItem>
        ))}
      </DropCommon>
    </Dropdown>
  );
};

const renderDropdownPicker = (value: string, vPadding: string = '16px', hPadding: string = '14px', title: string) => (
  <DropCommonPicker vPadding={vPadding} hPadding={hPadding}>
    <DropCommonPickerContent>
      <DrawerUserTypeLabel>{title}</DrawerUserTypeLabel>
      <DrawerUserTypeValue>{value}</DrawerUserTypeValue>
    </DropCommonPickerContent>
    <DropCommonPickerIcon icon="arrow_down" />
  </DropCommonPicker>
);

export default DropdownSingle;
