import { getQueryParam } from './getQueryParam';

export const setListPage = (currentPage: number | undefined, updatePage: (page?: number) => void) => {
  const paramsPage = getQueryParam('page');
  let page = currentPage;

  if (paramsPage && parseInt(paramsPage) !== page) {
    page = parseInt(paramsPage);
  }

  updatePage(page || 1);
};
