export const changePaymentMethods = (
  method: string,
  value: boolean,
  tag: string,
  paymentMethods: Record<string, string[]>,
  defaultPaymentMethods: string[],
) => {
  const tempPaymentMethods = { ...paymentMethods };

  if (tempPaymentMethods[tag]?.length) {
    const index = tempPaymentMethods[tag].indexOf(method);

    if (index > -1) {
      tempPaymentMethods[tag].splice(index, 1);
    } else {
      tempPaymentMethods[tag].push(method);
    }
  } else {
    tempPaymentMethods[tag] = defaultPaymentMethods?.filter(item => item !== method) || [];
  }

  return tempPaymentMethods;
};
