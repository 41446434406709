import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useAuth } from 'context/Auth';
import { PrivateRoute } from 'helpers/PrivateRoute';
import PageAuth from './Pages/Auth';
import { AppWrapper, ErrorWrapper, LoadingScreen } from './styles';
import { Loader } from 'styles/common';
import { renderErrors } from 'app/Components/common/Error';
import { getTranslations } from 'config/translation';
import { TError, TFetchStatus } from 'models';
import { Button } from '@insly/qmt-reactjs-ui-lib';
import { logout } from 'utils';
import Header from './Components/Header';

const Routes = () => {
  const [networkStatus, updateNetworkStatus] = useState<TFetchStatus>(null);
  const [errors, updateErrors] = useState<TError[]>([]);

  let auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated === 1) {
      getTranslations({
        updateNetworkStatus,
        onError: updateErrors
      });
    }
  }, [auth.isAuthenticated]);

  const Admin = React.lazy(() => import('./Pages/common'));
  const Translations = React.lazy(() => import('./Pages/Translations'));

  if (auth.isAuthenticated === -1 || (auth.isAuthenticated === 1 && networkStatus !== 'success')) {

    if (networkStatus === 'failed') {
      return (
        <LoadingScreen isAuthenticated={!!auth.isAuthenticated}>
          <ErrorWrapper>
            {renderErrors(errors)}
            <Button onClick={logout}>Log Out</Button>
          </ErrorWrapper>
        </LoadingScreen>
      );
    }

    return (
      <LoadingScreen isAuthenticated={!!auth.isAuthenticated}>
        <Loader />
      </LoadingScreen>
    );
  }

  return (
    <Router>
      <AppWrapper isAuthenticated={!!auth.isAuthenticated}>
        <Header />
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/auth/:section/:resetHash?">
              <PageAuth />
            </Route>
            <PrivateRoute path="/translations/:section">
              <Translations />
            </PrivateRoute>
            <PrivateRoute path="/:role/:section/:itemId?/:subsection?">
              <Admin />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Redirect to="/user/list" />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </AppWrapper>
    </Router>
  );
};

export default Routes;
