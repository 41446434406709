import React from 'react';
import { TStandardSectionStep } from 'models';
import { DrawerDataFormControls, DrawerDataFormControlsButton } from './styles';
import { translate } from 'utils';

export const renderControls = (step: TStandardSectionStep, isTouched: boolean, handleSaveData: () => void, resetForm?: () => void) => (
  <DrawerDataFormControls step={step} touched={isTouched}>
    {resetForm ? (
      <DrawerDataFormControlsButton size="l" preset="secondary" onClick={resetForm}>
        {translate({ key: 'common.button.cancel' })}
      </DrawerDataFormControlsButton>
    ) : null}
    <DrawerDataFormControlsButton size="l" onClick={handleSaveData}>
      {translate({ key: 'common.button.save' })}
    </DrawerDataFormControlsButton>
  </DrawerDataFormControls>
);

export const scrollElementToBottom = (element: HTMLDivElement | null) => {
  if (element !== null) {
    element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
  }
};

export const scrollElementToTop = (element: HTMLDivElement | null) => {
  if (element !== null) {
    element.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
