import styled from 'styled-components';

export const Error = styled.dl`
  background-color: ${({ theme }) => theme.colors.borderError};
  color: #fff;
  line-height: 25px;
  padding: 15px;
  margin-top: 10px;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const ErrorCode = styled.dt`
  font-family: ${({ theme }) => theme.font.bold};
`;

export const ErrorMessage = styled.dd`
  ${({ theme }) => theme.text.normal};
`;
