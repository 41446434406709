import React from 'react';
import { prepareBrokerOptions, TUserForm } from './index';
import { Input, Select } from '@insly/qmt-reactjs-ui-lib';
import { TTableData as TBrokerTableData } from '../Broker';
import { ContactsItem, ContactPersonRow, ContactsDataBlockText } from 'styles/common';
import { translate } from 'utils';

export const renderUsersForm = (
  form: TUserForm,
  handleChange: (key: string, value: string) => void,
  brokerList?: TBrokerTableData,
  isProfileForm?: boolean,
  brokerName?: string
) => (
  <ContactsItem>
    <ContactPersonRow gridTemplate="1fr 1fr">
      <Input
        label={translate({ key: 'master.user.summary.user_name' })}
        name="name"
        value={form.name}
        handleChange={(key, value) => handleChange(key as string, value as string)}
      />
      <Input
        label={translate({ key: 'master.common.email' })}
        name="email"
        value={form.email}
        handleChange={(key, value) => handleChange(key as string, value as string)}
        disabled={isProfileForm}
      />
    </ContactPersonRow>
    <ContactPersonRow gridTemplate="1fr">
      {isProfileForm ? (
        <ContactsDataBlockText>Agencja: {brokerName}</ContactsDataBlockText>
      ) : (
        <Select
          disabled={isProfileForm}
          label={translate({ key: 'master.broker.select' })}
          name="broker_id"
          value={form.broker_id}
          placeholder={translate({ key: 'common.select.placeholder' })}
          options={prepareBrokerOptions(brokerList)}
          searchable={true}
          handleChange={(key, value) => handleChange(key as string, value as string)}
        />
      )}
    </ContactPersonRow>
  </ContactsItem>
);
