import React from 'react';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';
import logoDefault from 'assets/images/logo.png';
import {
  ButtonPicker,
  HeaderDropdown,
  DropdownItemIcon,
  UserData,
  UserDataString,
  UserDataStringSecondary,
  UserDropDown,
  UserDropdownItem,
  UserDropdownItemWrapper,
  UserDropdownItemLabel,
  Tools,
  Logo,
  Header,
  UserDropdownItemSpan,
  Nav,
  NavLinkStyled,
  NavLinkIcon,
  NavLinkTitle,
  IconRemoveMasking,
} from './styles';
import { useHistory } from 'react-router-dom';
import { TMasquerade, TypeUser, useAuth } from 'context/Auth';
import { logout, preflightAPICall, translate } from 'utils';
import axios from 'axios';
import { API_MASQUERADE_URL } from 'app/Pages/User';

const INDEX_PAGE = '/user/list';
const DEFAULT_COMPANY_NAME = 'Insly';
const DEFAULT_CLASS_NAME = 'header';

type TNav = {
  icon: string,
  title: {
    key: string,
    fallback: string,
  },
  key: string,
  href: string,
};

const NAV: TNav[] = [
  {
    icon: 'nav_admin',
    title: { key: 'master.broker.list_title', fallback: 'Broker' },
    key: 'broker',
    href: 'broker/list'
  },
  {
    icon: 'nav_admin',
    title: { key: 'master.nav.user', fallback: 'User' },
    key: 'user',
    href: 'user/list'
  },
  {
    icon: 'nav_admin',
    title: { key: 'master.nav.admin', fallback: 'Admin' },
    key: 'admin',
    href: 'admin/list'
  },
  {
    icon: 'nav_translations',
    title: { key: 'master.nav.translations', fallback: 'Translations' },
    key: 'translations',
    href: 'translations/list'
  },
];

export interface ITopBar {
  logo?: string,
  user?: TypeUser,
  companyName?: string,
}

export const HeaderAuthed = ({ logo = logoDefault, companyName = DEFAULT_COMPANY_NAME, user }: ITopBar) => (
  <Header className={DEFAULT_CLASS_NAME}>
    <Logo to={INDEX_PAGE} className={`${DEFAULT_CLASS_NAME}__logo`}>
      <img src={logo} alt={companyName} />
    </Logo>
    {renderNav(createNav(filterNavByAdminRole(NAV, user?.role)))}
    {renderTools(user)}
  </Header>
);

const renderNav = (nav: JSX.Element[]): JSX.Element => (
  <Nav className={`${DEFAULT_CLASS_NAME}__nav`}>
    {nav && nav.map((item) => item)}
  </Nav>
);

const renderTools = (
  user?: TypeUser,
): JSX.Element => (
  <Tools className={`${DEFAULT_CLASS_NAME}__tools`}>
    {user && UserDropdown(user)}
  </Tools>
);

const UserDropdown = (user: TypeUser) => {
  let history = useHistory();
  let auth = useAuth();

  return (
    <UserData className={`${DEFAULT_CLASS_NAME}__user`}>
      <div>
        <UserDataString>{user?.name} {user?.masquerade ? <Masquerade masquerade={user.masquerade} /> : null}</UserDataString>
        <UserDataStringSecondary>{user?.email}</UserDataStringSecondary>
      </div>
      <HeaderDropdown
        picker={
          <ButtonPicker className={`${DEFAULT_CLASS_NAME}__user-button`}>
            <SvgIcon icon="user" />
          </ButtonPicker>
        }
      >
        <UserDropDown>
          <UserDropdownItemWrapper>
            <UserDropdownItem to="/">
              <DropdownItemIcon icon="settings" />
              <UserDropdownItemLabel>
                {translate({ key: 'common.auth.account_settings' })}
              </UserDropdownItemLabel>
            </UserDropdownItem>
          </UserDropdownItemWrapper>
          <UserDropdownItemWrapper>
            <UserDropdownItemSpan onClick={() => {
              auth.signout(() => {
                history.replace('/auth/login?logout=true');
              });
            }}>
              <DropdownItemIcon icon="signout" />
              <UserDropdownItemLabel>
                {translate({ key: 'common.auth.log_out' })}
              </UserDropdownItemLabel>
            </UserDropdownItemSpan>
          </UserDropdownItemWrapper>
        </UserDropDown>
      </HeaderDropdown>
    </UserData>
  );
};

const createNav = (items: { icon: string, title: { key: string, fallback?: string }, key: string, href?: string }[]) => items.map(item => (
  <NavLinkStyled
    to={`/${item.href || item.key}`}
    key={`site-nav-${item.key}`}
    className="nav-link"
    activeClassName="selected"
    isActive={(match, location) => location.pathname.indexOf(item.key) === 1}
  >
    <NavLinkIcon icon={item.icon} className="nav-link__icon" />
    <NavLinkTitle className="nav-link__title">
      {translate({ key: item.title.key })}
    </NavLinkTitle>
  </NavLinkStyled>
));

const filterNavByAdminRole = (navItems: TNav[], role?: string) => navItems.filter((item) => role !== 'admin' ? item.key !== 'translations' : item);

const Masquerade = ({ masquerade } : { masquerade: TMasquerade }) => (
  <>
    <div>
      (Masking: {masquerade.masquerade_user_props.name})
      <IconRemoveMasking
        icon="close"
        onClick={() => {
          preflightAPICall(() => {
            axios.delete(API_MASQUERADE_URL).then(() => {
              logout();
            }).catch(error => {
              console.error(error);
            });
          });
        }}
      />
    </div>
  </>
);

export default HeaderAuthed;
