import styled from 'styled-components';
import { SvgIcon, Table } from '@insly/qmt-reactjs-ui-lib';

export const DocumentsListStyled = styled(Table)`
  flex: 1;
  min-height: 300px;
  overflow: hidden;
  .qmt-ui-table__head {
    border-top: none;
  }
  .qmt-ui-table-row__column {
    display: flex;
    align-items: center;
  }
`;

export const DocumentIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const DocumentName = styled.div`
  ${({ theme }) => theme.text.normal};
  display: flex;
  span {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.subtle};
  }
`;

export const DocumentNameCol = styled.div<{ isUploading?: boolean, error?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ error }) => error ? 0.4 : null};
  ${({ isUploading, theme }) => isUploading ? `
    ${DocumentName} {
      color: ${theme.colors.primary};
    }
    ${DocumentIcon} {
      stroke: ${theme.colors.primary};
    }
  ` : null}
`;

export const Progress = styled.div`
  position: relative;
  width: 100px;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.strokeB};
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressPercents = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
`;

export const ProgressBar = styled.span`
  position: absolute;
  display: inline-block;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const DocError = styled.div`
  display: flex;
  align-items: center;
`;

export const DocErrorText = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 10px;
`;

export const DocErrorDeleteIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const DocumentQuoteName = styled.div`
  ${({ theme }) => theme.text.normal};
  span {
    color: ${({ theme }) => theme.colors.subtle};
  }
`;

export const DocumentDataString = styled.div`
  ${({ theme }) => theme.text.tiny};
  display: flex;
  div:not(:last-child),
  dt:not(:last-child) {
    margin-right: 8px;
  }
  span, dd {
    color: ${({ theme }) => theme.colors.subtle};
  }
`;

export const DocumentUserRole = styled.div`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.subtle};
  font-size: 12px;
`;

export const DocumentFormat = styled.div`
  color: ${({ theme }) => theme.colors.subtle};
  margin-left: 4px;
`;

export const DocumentSize = styled.div`
  color: ${({ theme }) => theme.colors.subtle};
  margin-left: 24px;
`;

export const DocumentControlsCol = styled.div`
  
`;

export const ControlsSet = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlsIconWrapper = styled.div`
  padding-left: 16px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
    padding-right: 16px;
  }
`;
