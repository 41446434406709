
type TTab = {
  key: string,
  title: string,
};

export const generateTabs = (tabs: TTab[], activeTab: string, updateActiveTab: (tab: string) => void) => tabs.map((item) => ({
  name: item.key,
  title: item.title,
  active: activeTab === item.key,
  onChange: () => updateActiveTab(item.key)
}));
