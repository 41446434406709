import { JSONSchemaProp } from 'models';
import { translate } from 'utils';

export const translateEnumInScheme = (prop: JSONSchemaProp, key: string, replace?: string[]) => {
  if (prop && prop.enum_translation_keys) {
    return translate({ key: prop.enum_translation_keys[key], fallback: key, replace });
  } else {
    return key;
  }
};
