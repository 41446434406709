import styled, { css } from 'styled-components';
import { SvgIcon, Button, Dropdown } from '@insly/qmt-reactjs-ui-lib';
import { Link, NavLink } from 'react-router-dom';
import { DefaultIcon } from 'styles/common';

const breakpointCollapsedMenu = '1070px';

const DropdownItem = css`
  display: flex;
  align-items: center;
  padding: 16px;
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
`;

export const Logo = styled(Link)`
  display: block;
  margin-right: 24px;
  flex-shrink: 0;
  img {
    display: block;
    width: 27px;
    height: 27px;
  }
`;

export const NavLinkIcon = styled(SvgIcon)`
  display: block;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-3px);
  }
`;

export const NavLinkTitle = styled.span`
  display: none;
`;

export const NavLinkStyled = styled(NavLink)`
  ${({ theme }) => theme.text.normal};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  margin-right: 24px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  &.selected {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Header = styled.header`
  padding: 8px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  
  @media (min-width: ${breakpointCollapsedMenu}) {
    ${Logo} {
      margin-right: 50px;
    }
    ${NavLinkStyled} {
      margin-right: 32px;
    }
    ${NavLinkIcon} {
      display: none;
    }
    ${NavLinkTitle} {
      display: block;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
`;

export const ButtonAddNew = styled(Button)`
  span {
    margin-right: 8px;
  }
  .qmt-ui-svg-icon {
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const Tools = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: 1fr;
  gap: 0 8px;
  .qmt-ui-search {
    transition: all 0.3s ease;
    height: 36px;
    &--active,
    &--has-value {
      flex: auto;
    }
  }
`;

export const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const NotificationsHeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-right: 16px;
`;

export const NotificationsMarkAsRead = styled.div<{ hasUnread?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.subtle};
  cursor: ${({ hasUnread }) => hasUnread ? 'pointer' : null};
`;

export const NotificationsHolder = styled.ul`
  margin-top: 8px;
`;

export const NotificationDropDown = styled.div`
  width: 360px;
`;

export const DropdownItemIcon = styled(SvgIcon)<{margin?: 'left' | 'right'}>`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null };
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null };
`;

export const NotificationTypeIconWrapper = styled.div<{unread: boolean}>`
  position: relative;
  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: ${({ unread }) => unread ? 'block' : 'none'};
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.white};
    transition: border-color 0.3s ease;
  }
`;

export const DropdownItemWrapper = styled.li`
  transition: background-color 0.3s ease;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
  &:last-child {
    border-radius: 0 0 ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverGray};
  }
`;

export const NotificationItem = styled.a`
  ${({ theme }) => theme.text.normal};
  color: ${({ theme }) => theme.colors.text};
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    ${NotificationTypeIconWrapper} {
      &:before {
        border-color: ${({ theme }) => theme.colors.hoverGray};
      }  
    }
  }
`;

export const NotificationItemHead = styled.div`
  display: flex;
  align-items: center;
`;


export const NotificationItemTitle = styled.span<{ unread: boolean }>`
  flex: 1;
  font-family: ${({ theme, unread }) => unread ? theme.font.bold : theme.font.medium};
  font-size: 14px;
  line-height: 20px;
`;

export const NotificationItemBody = styled.p<{ unread: boolean }>`
  ${({ theme }) => theme.text.small};
  padding: 0 40px;
  color: ${({ unread, theme }) => unread ? theme.colors.text : theme.colors.textSecondary}
`;

export const ButtonPicker = styled(Button)`
  position: relative;
  background-color: #F0F0F0;
  padding-left: 12px;
  padding-right: 12px;
  &:hover {
    background-color: #E2E2E2;
    border-color: #E2E2E2;
  }
  .qmt-ui-svg-icon {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.black};
    stroke: ${({ theme }) => theme.colors.black};
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: 3px;
  right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font: ${({ theme }) => theme.font.medium};
  font-size: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid #EDEDED;
  border-radius: 4px;
`;

export const HeaderDropdown = styled(Dropdown)`
  &.qmt-ui-dropdown--opened {
    ${ButtonPicker} {
      background-color: ${({ theme }) => theme.colors.black};
      border-color: ${({ theme }) => theme.colors.black};
      .qmt-ui-svg-icon {
        width: 24px;
        height: 24px;
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.white};
      }
      ${Badge} {
        border-color: ${({ theme }) => theme.colors.black};
      }
    }
  }
  
  .qmt-ui-dropdown__drop {
    top: calc(100% + 16px);
  }
`;

export const UserData = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const UserDataString = styled.div`
  ${({ theme }) => theme.text.normal};
  text-align: end;
  margin-right: 16px;
`;

export const UserDataStringSecondary = styled(UserDataString)`
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const UserDropDown = styled.ul`
  width: 240px;
`;

export const UserDropdownItemWrapper = styled(DropdownItemWrapper)`
  &:first-child {
    border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
  }
`;

export const UserDropdownItem = styled(Link)`
  ${DropdownItem}
`;

export const UserDropdownItemSpan = styled.span`
  ${DropdownItem};
  cursor: pointer;
`;

export const UserDropdownItemLabel = styled.span`
  ${({ theme }) => theme.text.normal};
  margin-left: auto;
`;

export const SearchResultsItemGroup = styled.div`
  padding: 8px 0;      
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const SearchResultsItemGroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
`;

export const SearchResultsItemGroupHeaderTitle = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.font.bold};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const SearchResultsItemGroupHeaderShowAll = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.textSecondary};
  cursor: pointer;
`;

export const SearchResultsIcon = styled(SvgIcon)<{ margin?: 'left' | 'right' }>`
  width: 24px;
  height: 24px;
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
  margin-left: ${({ margin }) => margin === 'left' ? '16px' : null};
`;

export const SearchResultsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px ${16+24}px;
  transition: background-color 0.3s ease;
  &:hover {
   background-color: #F0F0F0;
  }
`;

export const SearchResultsItemData = styled.div`
  flex: 1;
  padding: 0 16px;
`;

export const SearchResultsItemDataTitle = styled.div`
  ${({ theme }) => theme.text.normal};
  margin-bottom: 4px;
`;

export const SearchResultsItemDataAdditional = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.subtle};
  margin-bottom: 2px;
  .highlight {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SearchResultsItemControls = styled.div`
  display: flex;
`;

export const SearchResultsItemControlsItem = styled.div`
  &:not(:last-child) {
    display: flex;
    &:after {
      display: block;
      padding-left: 16px;
      margin-right: 16px;
      content: '';
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
    }
  }
  .qmt-ui-svg-icon {
    stroke: ${({ theme }) => theme.colors.black};
    width: 24px;
    height: 24px;
  }
`;

export const IconRemoveMasking = styled(DefaultIcon)`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;
