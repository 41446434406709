import { TError } from 'models';
import { combineErrors } from 'utils';
import React from 'react';
import { Error, ErrorCode, ErrorMessage } from './styles';

export const showErrors = (fieldsArray: string[], errors: TError[]) => {
  const combinedErrors = combineErrors(fieldsArray, errors);

  if (combinedErrors?.length) {
    return renderErrors(combinedErrors);
  }
};

export const renderErrors = (items: {code: string, message: string}[]) => items.map((item, index) => (
  <Error key={index}>
    {item.code ? (
      <ErrorCode>{item.code}:&nbsp;</ErrorCode>
    ) : null}
    <ErrorMessage>{item.message}</ErrorMessage>
  </Error>
));
