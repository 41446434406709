import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { processAPIError, filterUncommonErrors, translate } from 'utils';
import { renderErrors } from 'app/Components/common/Error';
import { shouldShowMoreShow, ShowMore } from 'app/Components/common/ShowMore';
import { TError } from 'models';
import { DrawerBodyStyled, DrawerCloseIcon, DrawerDataForm, DrawerHeaderRow, DrawerHeaderRowTitle } from 'app/Components/common/Drawer/styles';
import { TUser } from 'app/Pages/common';
import { TTableData as TBrokerTableData } from 'app/Pages/Broker';
import { renderUsersForm } from 'app/Pages/User/common';
import { renderControls, scrollElementToBottom } from 'app/Components/common/Drawer';

const USER_TEMPLATE = {
  id: '',
  name: '',
  email: '',
  broker_id: ''
};

interface IProps {
  handleDrawerClose: () => void,
  handlePostUser: (userForm: TUser, onError: (error: AxiosError) => void, onSuccess: () => void) => void,
  brokerList: TBrokerTableData,
}

//@TODO: Update Drawer Lib component and make children not required and remove unnecessary <div />
const DrawerAddUser = ({ handleDrawerClose, handlePostUser, brokerList }: IProps) => (
  <>
    {renderHeaderRow(translate({ key: 'master.add_user' }), handleDrawerClose)}
    <DrawerBody
      handlePostUser={handlePostUser}
      brokerList={brokerList}
    />
  </>
);

const DrawerBody = ({
  handlePostUser,
  brokerList
}: {
  handlePostUser: IProps['handlePostUser'],
  brokerList: IProps['brokerList'],
}) => {
  const bodyEl = useRef<HTMLDivElement>(null);
  const [showMore, updateShowMore] = useState(false);

  useEffect(() => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBodyScroll = () => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
  };

  return (
    <DrawerBodyStyled ref={bodyEl} onScroll={onBodyScroll} showMore={true}>
      <UserForm
        handlePostUser={handlePostUser}
        brokerList={brokerList}
      />
      <ShowMore
        show={showMore}
        onClick={() => scrollElementToBottom(bodyEl.current)}
      />
    </DrawerBodyStyled>
  );
};

const renderHeaderRow = (title: string, handleDrawerClose: () => void) => (
  <DrawerHeaderRow>
    <DrawerHeaderRowTitle>{title}</DrawerHeaderRowTitle>
    <DrawerCloseIcon icon="close" onClick={handleDrawerClose} />
  </DrawerHeaderRow>
);

const UserForm = ({
  handlePostUser,
  brokerList
}: {
  handlePostUser: IProps['handlePostUser'],
  brokerList: IProps['brokerList'],
}) => {


  const [form, updateForm] = useState({
    ...USER_TEMPLATE,
  });

  const [errors, updateErrors] = useState<TError[]>([]);
  const [isTouched, updateIsTouched] = useState(false);

  const [isSubmitting, updateIsSubmitting] = useState(false);

  const handleChange = (key: string, value: string) => {
    const touchedForm = { ...form };
    // @ts-ignore
    touchedForm[key] = value;

    updateErrors([]);
    updateIsTouched(true);
    updateForm(touchedForm);
  };

  const handleSaveData = () => {
    updateIsSubmitting(true);

    const postUserData = {
      broker_id: form.broker_id,
      props: {
        name: form.name,
        email: form.email,
      }
    };

    handlePostUser(postUserData, (error) => {
      updateErrors(() => processAPIError(error, true) as TError[]);
      updateIsSubmitting(false);
    }, () => {
      updateIsTouched(false);
      updateIsSubmitting(false);
    });
    updateIsTouched(false);
  };

  return (
    <DrawerDataForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      {renderUsersForm(form, handleChange, brokerList)}
      {errors?.length ? renderErrors(filterUncommonErrors(errors)) : null}
      {(isTouched) ? renderControls('list', isTouched, handleSaveData,) : null}
    </DrawerDataForm>
  );

};

export default DrawerAddUser;
