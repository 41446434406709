import styled from 'styled-components';
import { Select } from '@insly/qmt-reactjs-ui-lib';

export const SelectBroker = styled(Select)`
  border: none;
  height: auto;
  .qmt-ui-dropdown__picker {
    padding: 0;
  }
`;
