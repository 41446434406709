import React, { createContext, useContext, useState } from 'react';
import { IDialog } from '@insly/qmt-reactjs-ui-lib/dist/components/Dialog';
import { INotification } from '@insly/qmt-reactjs-ui-lib/dist/components/Notification';

const AppContext = createContext({
  dialogProps: {
    show: false,
    onAccept: () => {},
    onDecline: () => {},
  },
  notificationProps: {
    show: false,
    message: '',
    preset: '',
  },
  showDialog: (props: Partial<IDialog>) => {},
  hideDialog: (cb?: () => void) => {},
  showNotification: (props: Partial<INotification>) => {},
  hideNotification: () => {},
});

export const ProvideApp = ({ children }: {children: JSX.Element}) => {
  const value = useProvideApp();
  return (
    // @ts-ignore
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);

const useProvideApp = () => {

  const [dialogProps, updateDialogProps] = useState<Partial<IDialog>>({});
  const [notificationProps, updateNotificationProps] = useState<Partial<INotification>>({});

  const showDialog = (props: Partial<IDialog>) => {
    updateDialogProps({
      show: true,
      ...props
    });
  };

  const showNotification = (props: Partial<INotification>) => {
    updateNotificationProps({
      show: true,
      ...props
    });
  };

  const hideDialog = (cb: () => void) => {
    if (cb) {
      cb();
    }
    updateDialogProps({
      ...dialogProps,
      show: false,
    });
  };

  const hideNotification = () => {
    updateNotificationProps({
      ...notificationProps,
      show: false,
    });
  };

  return {
    dialogProps,
    notificationProps,
    showDialog,
    hideDialog,
    showNotification,
    hideNotification,
  };
};
