import styled from 'styled-components';
import { Button, Drawer } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';
import { SHOW_MORE_EL_HEIGHT } from '../ShowMore';
import { TStandardSectionStep } from 'models';

const stepDrawerRollingTime = '0.8s';
const drawerRollingTime = '0.5s';

export const DrawerStyled = styled(Drawer)<{ step?: TStandardSectionStep }>`
  position: relative;
  display: flex;
  flex-direction: column;
  transform: ${({ step }) => step === 'profile' ? 'translateX(calc(-100vw + 520px))' : 'translateX(0)'};
  transition: transform ${stepDrawerRollingTime} ease, margin ${drawerRollingTime} ease;
  padding-right: 60px;
`;

export const DrawerHeaderRow = styled.div`
  display: flex;
  padding-bottom: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const DrawerHeaderRowTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.text.normal};
  font-size: 24px;
  flex: 1;
`;

export const DrawerCloseIcon = styled(DefaultIcon)`
  stroke: ${({ theme }) => theme.colors.black};
`;

export const DrawerBodyStyled = styled.div<{ showMore?: boolean }>`
  flex: 1;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  grid-row-gap: 32px;
  padding: 30px 52px 0 0;
  overflow-y: auto;
  margin-right: -60px;
  margin-bottom: ${({ showMore }) => showMore ? `-${SHOW_MORE_EL_HEIGHT + 24}px` : null}; //24 Drawer Bottom Padding
`;

export const DrawerDataForm = styled.div<{ controlsShown?: boolean }>`
  padding-bottom: ${({ controlsShown }) => controlsShown ? '76px' : null};
  transition: padding-bottom 0.5s ease;
`;

export const DrawerUserTypeLabel = styled.div`
  ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const DrawerUserTypeValue = styled.div`
  ${({ theme }) => theme.text.normal};
`;

export const DrawerUserForm = styled.div<{ controlsShown?: boolean }>`
  padding-bottom: ${({ controlsShown }) => controlsShown ? '76px' : null};
  transition: padding-bottom 0.5s ease;
`;

export const DrawerUserFormAdditional = styled.div`
  margin-top: 32px;
`;

export const DrawerUserFormAdditionalRow = styled.div<{gridTemplate?: string}>`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate ? gridTemplate : '1fr'};
  grid-column-gap: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const DrawerDataFormControls = styled.div<{ step?: string, touched?: boolean }>`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  ${({ step, touched, theme }) => step === 'profile' ? `
    position: absolute;
    top: ${touched ? 'calc(100% - 76px)' : '100%'};
    left: 40px;
    right: 40px;
    padding: 10px 0;
    background-color: ${theme.colors.sidebarBg};
    transition: top 0.5s ease;
    border-top: 1px solid ${theme.colors.strokeB};
  ` : null}
`;

export const DrawerDataFormControlsButton = styled(Button)`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;
