import { TUser } from 'app/Pages/common';
import React, { useEffect, useState } from 'react';
import { ResetPasswordForm } from './styles';
import axios, { AxiosError } from 'axios';
import { TUserForm } from 'app/Pages/User';
import { TError, TStandardSectionStep } from 'models';
import {
  processAPIError,
  filterUncommonErrors,
  preflightAPICall,
  errorNotification,
  translate,
} from 'utils';
import { DrawerDataForm } from 'app/Components/common/Drawer/styles';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { renderUsersForm } from 'app/Pages/User/common';
import { renderErrors } from 'app/Components/common/Error';
import { renderControls } from 'app/Components/common/Drawer';
import { useApp } from 'context/App';
import { Button } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';
import apiConfig from 'config/api';
import { UserRole } from './UserRole';

const API_RESET_PASSWORD = `${apiConfig.IDENTIFIER}/password/force_reset/${apiConfig.TENANT}`;
const API_RESEND_TEMP_PASSWORD = `${apiConfig.MASTER}/users/%USER_ID%/password/resend`;

interface IProps {
  profile: TUser,
  step: TStandardSectionStep,
  handlePatchUser: (selectedUser: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  updateUserProfile: (user: TUser) => void,
}

const Summary = ({ profile, handlePatchUser, updateUserProfile, step }: IProps) => {

  const handleSaveUser = (userForm: Partial<TUser>, onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    const requestObject: TUser = {
      ...profile,
      ...userForm
    };

    handlePatchUser(requestObject, onError, onSuccess);
  };

  if (step === 'profile') {
    return (
      <>
        <UserForm
          profile={profile}
          handlePatchUser={handleSaveUser}
        />
        <UserRole
          profile={profile}
          handlePatchUser={handleSaveUser}
          updateUserProfile={updateUserProfile}
        />
        <UserPassword
          profile={profile}
        />
      </>
    );
  }

  return null;

};

const UserForm = ({
  profile,
  handlePatchUser,
}: {
  profile: TUser,
  handlePatchUser: (userForm: TUser, onError: (error: AxiosError) => void, onSuccess: () => void) => void,
}) => {

  const [form, updateForm] = useState<TUserForm>(fillForm(profile));

  const [errors, updateErrors] = useState<TError[]>([]);
  const [isTouched, updateIsTouched] = useState(false);

  const [isSubmitting, updateIsSubmitting] = useState(false);

  useEffect(() => {
    updateForm(fillForm(profile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.id]);

  const handleChange = (key: string, value: string) => {
    const touchedForm = { ...form };
    // @ts-ignore
    touchedForm[key] = value;

    updateErrors([]);
    updateIsTouched(true);
    updateForm(touchedForm);
  };

  const handleSaveData = () => {
    updateIsSubmitting(true);

    const patchUserData = {
      id: profile.id,
      broker_id: form.broker_id,
      props: {
        ...profile.props,
        name: form.name,
        email: form.email,
      }
    };

    handlePatchUser(patchUserData, (error) => {
      updateErrors(() => processAPIError(error, true) as TError[]);
      updateIsSubmitting(false);
    }, () => {
      updateIsTouched(false);
      updateIsSubmitting(false);
    });
    updateIsTouched(false);
  };

  const resetForm = () => {
    updateForm(fillForm(profile));
    updateErrors([]);
    updateIsTouched(false);
  };

  return (
    <DrawerDataForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      {renderUsersForm(form, handleChange, undefined, true, profile.broker?.name)}
      {errors?.length ? renderErrors(filterUncommonErrors(errors)) : null}
      {(isTouched) ? renderControls('list', isTouched, handleSaveData, resetForm) : null}
    </DrawerDataForm>
  );

};

const fillForm = (userData: TUser) => ({
  name: userData.props.name,
  email: userData.props.email,
  broker_id: userData.broker_id
});

const UserPassword = ({
  profile,
}: {
  profile: TUser,
}) => {

  const { showNotification, showDialog } = useApp();

  const [isSubmitting, updateIsSubmitting] = useState(false);

  const onResetPassword = (username: string) => {
    updateIsSubmitting(true);
    preflightAPICall(() => {
      axios.post(API_RESET_PASSWORD, {
        Username: username
      }).then(() => {
        showNotification({
          message: translate({ key: 'master.notification.password_reset_email_sent' }),
          preset: 'success',
          autoHide: true
        });
      }).catch(error => {
        errorNotification(error, showNotification, false);
      }).finally(() => updateIsSubmitting(false));
    });
  };

  const onResetTempPassword = (userId: string) => {
    updateIsSubmitting(true);
    preflightAPICall(() => {
      axios.post(API_RESEND_TEMP_PASSWORD.replace('%USER_ID%', userId)).then(() => {
        showNotification({
          message: translate({ key: 'master.notification.password_reset_email_sent' }),
          preset: 'success',
          autoHide: true
        });
      }).catch(error => {
        errorNotification(error, showNotification, false);
      }).finally(() => updateIsSubmitting(false));
    });
  };

  const onResetClick = () => {
    showDialog({
      body: translate({ key: 'master.dialog.reset_password' }),
      buttonAccept: translate({ key: 'master.dialog.button.reset_password' }),
      buttonDecline: translate({ key: 'common.button.cancel' }),
      onAccept: () => onResetPassword(profile.props.email),
    });
  };

  const onResetTempClick = () => {
    showDialog({
      body: translate({ key: 'master.dialog.reset_password' }),
      buttonAccept: translate({ key: 'master.dialog.button.reset_password' }),
      buttonDecline: translate({ key: 'common.button.cancel' }),
      onAccept: () => onResetTempPassword(profile.id as string),
    });
  };

  return (
    <ResetPasswordForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      {renderPasswordReset(onResetClick)}
      {renderTempPasswordReset(onResetTempClick)}
    </ResetPasswordForm>
  );

};

const renderPasswordReset = (
  onResetClick: () => void
) => (
  <Button preset="ternary" onClick={onResetClick}>
    <>
      <>
        {translate({ key: 'master.user.reset_password' })}
      </>
      <DefaultIcon icon="key" />
    </>
  </Button>
);

const renderTempPasswordReset = (
  onResetClick: () => void
) => (
  <Button preset="ternary" onClick={onResetClick}>
    <>
      <>
        {translate({ key: 'master.user.resend_password' })}
      </>
      <DefaultIcon icon="key" />
    </>
  </Button>
);

export default Summary;
