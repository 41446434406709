import React from 'react';
import { Loader, LoadingOverlayStyled } from 'styles/common';

const LoadingOverlay = () => (
  <LoadingOverlayStyled>
    <Loader />
  </LoadingOverlayStyled>
);

export default LoadingOverlay;
