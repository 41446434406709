import apiConfig, { API_USER_LIST_URL } from 'config/api';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { makeRequestQuery, preflightAPICall, processAPIError, setListPage, translate } from 'utils';
import axios, { AxiosError } from 'axios';
import { UserList } from './list';
import { TError, TListData, TStandardSectionStep, TUserStatus } from 'models';
import { TTableData as TBrokerTableData } from '../Broker';
import Drawer from './drawer';
import { errorNotification } from 'utils';
import { useApp } from 'context/App';
import { UserProfile } from './profile';
import { setListPageSize } from 'app/Pages/common/List';
import { TDrawerType, TUser } from '../common';

export const API_USER_ITEM_URL = `${apiConfig.MASTER}/users/%USER_ID%`;
const API_USER_CREATE = `${apiConfig.MASTER}/users`;
const API_BROKER_LIST_URL = `${apiConfig.MASTER}/brokers`;
export const API_MASQUERADE_URL = `${apiConfig.MASTER}/masquerade`;

export type TUserForm = {
  id?: string,
  name: string,
  email: string,
  broker_id: string,
  role?: string,
  communication_language?: string[],
};

export type TProfileTab = 'summary' | 'password' | 'insurers';

export type TTableData = TListData & { results?: TUser[] };

interface IProps {
  selectedUser: TUser | null,
  updateSelectedUser: (user: TUser | null) => void,
  drawerType: TDrawerType,
  handleDrawerClose: (page?: number) => void,
  updateStep: (href: string) => void,
  handleAddUserClick: () => void,
  handleUpdateSelectedUser: (data: TUser | null) => void,
}

const PageUser = ({
  selectedUser,
  updateSelectedUser,
  drawerType,
  handleDrawerClose,
  updateStep,
  handleAddUserClick,
  handleUpdateSelectedUser,
}: IProps) => {
  const location = useLocation();
  let history = useHistory();

  const [dataStatus, updateDataStatus] = useState('loading');
  const [userList, updateUserList] = useState<TTableData>({});
  const [brokerList, updateBrokerList] = useState<TBrokerTableData>({});
  const [searchQuery, updateSearchQuery] = useState<string>();
  const [filterBroker, updateFilterBroker] = useState<string>();
  const [userDataStatus, updateUserDataStatus] = useState('success');
  const [userErrors, updateUserErrors] = useState<TError[]>([]);
  const [noResultsText, updateNoResultsText] = useState('');
  const [page, updatePage] = useState<number | undefined>();
  const [isMasking, updateIsMasking] = useState(false);

  const {
    section,
    subsection,
    itemId
  } = useParams<{ section: TStandardSectionStep, subsection: TProfileTab, itemId: string }>();

  const { showNotification } = useApp();

  useEffect(() => {
    if (section === 'list' && page) {
      getBrokerList();
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, page]);

  useEffect(() => {
    if (typeof searchQuery !== 'undefined' || typeof filterBroker !== 'undefined') {
      history.replace(`${location.pathname}`);

      if (!page || page === 1) {
        getList();
      } else {
        updatePage(1);
      }
    } else {
      setListPage(page, updatePage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filterBroker]);

  useEffect(() => {
    if (section === 'profile' && !selectedUser && itemId && !['loading', 'error'].includes(userDataStatus)) {
      getUser(itemId);
    }
  });

  const getList = () => {
    updateDataStatus('loading');
    let query: Record<string, string | number> = {};

    query.page_size = setListPageSize();

    if (page) {
      query.page = page;
    }

    if (searchQuery) {
      query.search = encodeURIComponent(searchQuery);
    }

    if (filterBroker) {
      query.broker_id = filterBroker;
    }

    const queryString = makeRequestQuery(query);

    preflightAPICall(() => {
      axios.get(`${API_USER_LIST_URL}${queryString}`).then(response => {
        const data = response.data;
        dataLoading(data);

      }).catch(error => {

        dataLoading({
          results: [],
        });

        updateNoResultsText(() => processAPIError(error) as string);

      });
    });
  };

  const getBrokerList = () => {
    preflightAPICall(() => {
      axios.get(`${API_BROKER_LIST_URL}?page_size=1000`).then(response => {
        const data = response.data;
        updateBrokerList(data);
      }).catch(error => {
        errorNotification(error, showNotification);
      });
    });
  };

  const dataLoading = (data: TTableData) => {
    data = {
      ...data,
      results: data.results
    };
    updateUserList(data);
    updateDataStatus('success');
  };

  const handlePostUser = (requestObject: TUser, onError?: (error: AxiosError) => void) => {
    preflightAPICall(() => {
      axios.post(API_USER_CREATE, {
        ...requestObject
      }).then(response => {
        const data = response.data;
        let results = [
          data
        ];
        if (userList.results?.length) {
          results = [
            ...results,
            ...userList.results
          ];
        }
        handleDrawerClose();
        updateUserList({
          ...userList,
          results: results
        });
      }).catch(error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      });
    });
  };

  const handleDeleteUser = (userId?: string, onError?: (error: AxiosError) => void) => {
    userId = typeof userId === 'string' ? userId : selectedUser?.id;
    if (userId) {
      preflightAPICall(() => {
        axios.delete(API_USER_ITEM_URL.replace('%USER_ID%', userId as string)).then(() => {
          onUserDeleted(userId as string);
        }).catch(error => {
          if (onError) {
            onError(error);
          } else {
            console.error(error);
          }
        });
      });
    }
  };

  const onUserDeleted = (translationId: string) => {
    const list = userList.results?.length ? [...userList.results] : [];

    if (list?.length) {
      const customerIndex = list.findIndex(i => i.id === translationId);
      if (customerIndex !== -1) {
        list.splice(customerIndex, 1);
      }
    }

    updateStep(`list?page=${page}`);
    updateSelectedUser(null);
    updateUserList({
      ...userList,
      results: list,
    });
  };

  const handlePatchUser = (user: TUser, onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    preflightAPICall(() => {
      axios.patch(API_USER_ITEM_URL.replace('%USER_ID%', user.id as string), {
        ...user
      }).then(response => {
        const data = response.data;
        const results = userList.results?.length ? [...userList.results] : [];
        const itemIndex = results.findIndex(i => i.id === data.id);
        if (itemIndex !== -1) {
          results[itemIndex] = data;
          updateUserList({
            ...userList,
            results: results
          });
        }
        if (selectedUser && (selectedUser.id === data.id)) {
          updateSelectedUser(data);
        }

        if (onSuccess) {
          onSuccess();
        }

      }).catch(error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      });
    });
  };

  const handleUpdateUserStatus = (status: TUserStatus, onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    preflightAPICall(() => {
      axios.post(`${API_USER_ITEM_URL.replace('%USER_ID%', selectedUser?.id as string)}/status/update`, {
        status: +(status),
      }).then(response => {
        const data = response.data;
        const results = userList.results?.length ? [...userList.results] : [];
        const itemIndex = results.findIndex(i => i.id === data.id);

        if (itemIndex !== -1) {
          results[itemIndex] = data;
          updateUserList({
            ...userList,
            results: results
          });
        }

        if (selectedUser && (selectedUser.id === data.id)) {
          updateSelectedUser(data);
        }

        if (onSuccess) {
          onSuccess();
        }

      }).catch(error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      });
    });
  };

  const handleMaskUser = (userId: string, onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    updateIsMasking(true);
    preflightAPICall(() => {
      axios.patch(`${API_MASQUERADE_URL}/${userId}`).then(() => {

        if (onSuccess) {
          onSuccess();
        }
        updateIsMasking(false);
      }).catch(error => {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
        updateIsMasking(false);
      });
    });
  };

  const filterByBroker = (value: string) => updateFilterBroker(value);

  const handleSearchChange = (value: string) => updateSearchQuery(value);

  const handleListChangePage = (page: number) => {
    history.replace(`/user/list?page=${page}`);
    updatePage(page);
  };

  const getUser = (customerId: string) => {
    updateUserDataStatus('loading');

    preflightAPICall(() => {
      axios.get(API_USER_ITEM_URL.replace('%USER_ID%', customerId)).then(response => {
        const data = response.data;
        updateSelectedUser(data);
        updateUserDataStatus('success');
      }).catch(error => {
        updateUserDataStatus('error');
        updateUserErrors(() => processAPIError(error, true) as TError[]);
      });
    });
  };

  return (
    <>
      <UserList
        step={section}
        title={translate({ key: 'master.user.list_title' })}
        dataStatus={dataStatus}
        userList={userList}
        brokerList={brokerList}
        updateSelectedUser={handleUpdateSelectedUser}
        selectedUser={selectedUser}
        drawerType={drawerType}
        noResultsText={noResultsText}
        filterBroker={filterBroker}
        filterByBroker={filterByBroker}
        handleListChangePage={handleListChangePage}
        handleSearchChange={handleSearchChange}
        handleAddUserClick={handleAddUserClick}
      />
      <UserProfile
        step={section}
        updateStep={updateStep}
        activeTab={subsection}
        userId={itemId}
        profile={selectedUser}
        dataStatus={userDataStatus}
        userErrors={userErrors}
        handlePatchUser={handlePatchUser}
        updateSelectedUser={updateSelectedUser}
      />
      <Drawer
        type={drawerType}
        selectedUser={selectedUser}
        brokerList={brokerList}
        userId={itemId}
        handleDrawerClose={() => handleDrawerClose(page)}
        updateStep={updateStep}
        step={section}
        dataStatus={userDataStatus}
        errors={userErrors}
        isMasking={isMasking}
        handlePatchUser={handlePatchUser}
        handlePatchUserStatus={handleUpdateUserStatus}
        handleMaskUser={handleMaskUser}
        handleDeleteUser={handleDeleteUser}
        handlePostUser={handlePostUser}
        updateSelectedUser={updateSelectedUser}
      />
    </>
  );
};

export const prepareBrokerOptions = (brokerList?: TBrokerTableData) => {
  let options: {key: string, value: string}[] = [];

  if (brokerList?.results?.length) {
    options = brokerList.results.map(broker => ({
      key: broker.id,
      value: broker.name
    }));
  }

  return options;
};

export default PageUser;

