import React from 'react';
import { useAuth } from 'context/Auth';
import HeaderAuthed from './Authed';

const Header = () => {
  const auth = useAuth();

  return auth.isAuthenticated === 1 ? (
    <HeaderAuthed
      user={auth.user}
    />
  ) : null;
};

export default Header;
