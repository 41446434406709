import styled from 'styled-components';
import { Loader } from 'styles/common';

export const PageAuthStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-gap: 0;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
  .header {
    grid-area: header;
  }
  .content {
    grid-area: content;
  }
  .footer {
    grid-area: footer;
  }
`;

export const Content = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoaderAuth = styled(Loader)`
  position: absolute;
`;
