import styled from 'styled-components';
import { DefaultIcon, Drop, DropItem } from 'styles/common';
import { SvgIcon } from '@insly/qmt-reactjs-ui-lib';

export const DropCommonPicker = styled.div<{ vPadding?: string, hPadding?: string }>`
  display: flex;
  align-items: center;
  padding: ${({ vPadding }) => vPadding} ${({ hPadding }) => hPadding};
`;

export const DropCommon = styled(Drop)`
  width: 200px;
`;

export const DropCommonItem = styled(DropItem)`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
`;

export const DropCommonLabel = styled.div`
  flex: 1;
`;

export const DropCommonIcon = styled(SvgIcon)`
  width: 30px;
  height: 30px;
`;

export const DropCommonPickerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DropCommonPickerIcon = styled(DefaultIcon)<{ isRotated?: boolean }>`
  margin-left: 16px;
  transform: ${({ isRotated }) => isRotated && 'rotate(180deg)'};
`;
