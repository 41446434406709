import styled from 'styled-components';
import { Table, Select } from '@insly/qmt-reactjs-ui-lib';
import { TStandardSectionStep } from 'models';
import { DefaultIcon } from 'styles/common';

const stepOpacityTime = '0.5s';

export const ColLabel = styled.div`
  ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.colors.subtle};
  text-transform: uppercase;
`;

export const ColData = styled.div`
  ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const List = styled.div<{ step?: TStandardSectionStep, noPadding?: boolean }>`
  display: flex;
  justify-content: center;
  flex: 1;
  padding: ${({ noPadding }) => noPadding ? null : '54px 40px 0'};
  opacity: ${({ step }) => step === 'list' ? 1 : 0};
  transition: opacity ${stepOpacityTime} ease;
`;

export const ListInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1080px;
`;

export const TableStyled = styled(Table)`
  flex: 1;
  overflow: hidden;
  .qmt-ui-table-row {
    height: 60px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.text.headerH1};
  line-height: 24px;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 66px;
`;

export const TranslationTypeFilter = styled(Select)`
  border: none;
  height: auto;
  .qmt-ui-dropdown__picker {
    padding: 0;
  }
`;

export const DeleteColIcon = styled(DefaultIcon)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled && 0.2};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  &.qmt-ui-svg-icon--clickable {
    margin-left: auto;
  }
`;
