import styled from 'styled-components';

export const ResetPasswordForm = styled.div`
  display: flex;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
  margin-top: 20px;
  padding-top: 20px;
`;

export const SectionRole = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const SectionRoleTitle = styled.div`
  ${({ theme }) => theme.text.headerH2};
`;

export const SectionRoleSubtitle = styled.div`
  ${({ theme }) => theme.text.headerH3};
  margin-bottom: 6px;
`;

export const SectionRoleFromRow = styled.div`
  display: flex;
  gap: 16px;
  
`;

export const SectionRoleControlsRow = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 10px;
  justify-content: end;
`;
