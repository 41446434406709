const PAGE_SIZE = 50;
const LIST_ITEM_HEIGHT = 60;
const LIST_PAGINATION_HEIGHT = 66;

export const setListPageSize = (
  pageSize: number = PAGE_SIZE,
  listPaginationHeight: number = LIST_PAGINATION_HEIGHT,
  listItemHeight: number = LIST_ITEM_HEIGHT
) => {
  const listElement = document.querySelector('.qmt-ui-table__body');
  const paginationContainer = document.querySelector('.pagination');
  const bottomPadding = paginationContainer ? 0 : listPaginationHeight;

  if (listElement) {
    const calculatedPageSize = +((listElement.clientHeight - bottomPadding) / listItemHeight).toFixed();
    return calculatedPageSize > 0 ? calculatedPageSize : 1;
  }

  return pageSize;
};
