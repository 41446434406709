import React from 'react';
import { ProvideAuth } from 'context/Auth';
import { ProvideApp } from 'context/App';

import Routes from './routes';
import Misc from './Components/Misc';

const App = () => (
  <ProvideAuth>
    <ProvideApp>
      <>
        <Routes />
        <Misc />
      </>
    </ProvideApp>
  </ProvideAuth>
);

export default App;
