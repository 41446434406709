import styled from 'styled-components';
import { TStandardSectionStep } from 'models';

const stepOpacityTime = '0.5s';

export const PageStyled = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;

  .qmt-ui-table-row__column {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div<{ step?: TStandardSectionStep }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 520px;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 60px 60px 20px 40px;
  z-index: ${({ step }) => step === 'profile' ? 0 : -1};
  opacity: ${({ step }) => step === 'profile' ? 1 : 0};
  transition: opacity ${stepOpacityTime} ease;
`;

export const ProfileInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 714px;
`;
