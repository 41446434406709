import React from 'react';
import { ListPagination, PaginationControl, PaginationData } from './styles';

const Pagination = (list: {page: number, last_page: number}, handlePageChange: (page: number) => void) => (
  <ListPagination className="pagination">
    <PaginationControl
      disabled={list.page === 1}
      direction="prev"
      icon="arrow_left"
      onClick={list.page && list.page > 1 ? () => handlePageChange(list.page as number - 1) : undefined}
    />
    <PaginationData>{list.page} z {list.last_page}</PaginationData>
    <PaginationControl
      disabled={list.page === list.last_page}
      direction="next"
      icon="arrow_left"
      onClick={list.page && list.page < list.last_page ? () => handlePageChange(list.page as number + 1) : undefined}
    />
  </ListPagination>
);

export default Pagination;
