import styled from 'styled-components';
import { Tabs } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';

export const TabsStyled = styled(Tabs)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  overflow: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  min-height: 38px;
  
  .qmt-ui-tabs__tab {
    color: ${({ theme }) => theme.colors.textOff};
    transform: translateY(1px);
    padding-bottom: 15px;
    font-size: 20px;
    
    &:not(:last-child) {
      margin-right: 32px;
    }
    
    &--active,
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const TabsControlIconNextStyled = styled(DefaultIcon)<{ rotateLeft?: boolean }>`
  transform: rotate(-90deg);
  position: absolute;
  right: -35px;
`;

export const TabsControlIconPrevStyled = styled(DefaultIcon)<{ rotateLeft?: boolean }>`
  transform: rotate(90deg);
  position: absolute;
  left: -35px;
`;

export const TabsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ProfileBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 60px 32px 0;
  margin-right: -60px;
  flex: 1;
  overflow-y: auto;
`;
