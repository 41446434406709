import React, { useEffect, useState } from 'react';
import {
  ButtonSubmitPaymentMethods,
  InsurerStyled,
  InsurerStyledHeader,
  InsurerStyledHeaderInsurerDataItem,
  InsurerStyledHeaderInsurerDataItemIcon,
  InsurerStyledHeaderInsurerDataItemTitle,
  InsurerStyledHeaderSide,
  InsurerStyledHeaderTitle,
  PaymentMethodsBody,
  PaymentMethodsContainer,
  PaymentMethodsStyled,
  PaymentMethodsStyledTitle,
  PaymentMethodsTitle,
  PaymentMethodsTitleContainer
} from './styles';
import { Switch } from '@insly/qmt-reactjs-ui-lib';
import { DropCommonPickerIcon } from '../DropdownSingle/styles';
import { TFetchStatus } from 'models';
import { TInsurer } from 'app/Pages/User/profile/body/Insurers';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { translate } from 'utils/translate';

interface IInsurer {
  insurer: TInsurer,
  userInsurerId?: string,
  isIssuingEnabled?: boolean,
  isInsurerEnabled?: boolean,
  handleChangeInsurer?: (insurerId: string, value: boolean) => void,
  handleChangeIssuing?: (key: string, value: boolean) => void,
  onSubmitData: () => void,
  onChangePaymentMethods: (method: string, value: boolean, tag: string, defaultPaymentMethods: string[]) => void,
  isEditable: boolean,
  dataFetchStatus: TFetchStatus,
  paymentMethods: Record<string, string[]>,
  defaultPaymentMethods?: string[],
}

const Insurer = ({
  insurer,
  userInsurerId,
  isInsurerEnabled,
  isIssuingEnabled,
  handleChangeInsurer,
  handleChangeIssuing,
  onSubmitData,
  onChangePaymentMethods,
  isEditable,
  dataFetchStatus,
  paymentMethods,
  defaultPaymentMethods
}: IInsurer) => {
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  const isChecked = (method: string) => !(paymentMethods?.[insurer.tag]?.length) || paymentMethods[insurer.tag].includes(method);

  useEffect(() => {
    dataFetchStatus === 'success' && setIsFormTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchStatus]);

  return (
    <>
      <InsurerStyled>
        <InsurerStyledHeader>
          <InsurerStyledHeaderSide>
            {isEditable && <Switch
              name={isInsurerEnabled ? userInsurerId : insurer.id} // INSLYPROD-5326: id is related to checked/unchecked switch
              handleChange={(name, value) => handleChangeInsurer && handleChangeInsurer(name as string, value as boolean)}
              checked={isInsurerEnabled}
            />}
            <InsurerStyledHeaderTitle>
              {insurer.title}
            </InsurerStyledHeaderTitle>
          </InsurerStyledHeaderSide>
          {isEditable && <HasIssuing
            userInsurerId={userInsurerId}
            isInsurerEnabled={isInsurerEnabled}
            isIssuingEnabled={isIssuingEnabled}
            handleChangeIssuing={handleChangeIssuing}
          />}
          <PaymentMethodsTitleContainer
            disabled={!defaultPaymentMethods || !Array.isArray(defaultPaymentMethods)}
            onClick={() => setShowPaymentMethods(!showPaymentMethods)}
          >
            <DropCommonPickerIcon icon="arrow_down" isRotated={showPaymentMethods} />
            <PaymentMethodsStyledTitle>{translate({ key: 'common.payment_methods' })}</PaymentMethodsStyledTitle>
          </PaymentMethodsTitleContainer>
        </InsurerStyledHeader>
      </InsurerStyled>
      {showPaymentMethods && (
        <PaymentMethodsContainer>
          {dataFetchStatus === 'loading' ? <LoadingOverlay /> : null}
          {defaultPaymentMethods?.map((method: string) =>
            <PaymentMethodsStyled key={method}>
              <PaymentMethodsBody>
                <PaymentMethodsTitle>{translate({ key: `common.payment_method.${method}` })}</PaymentMethodsTitle>
                <Switch
                  name={method}
                  checked={isChecked(method)}
                  handleChange={(name, value) => {
                    onChangePaymentMethods(name as string, value as boolean, insurer.tag, defaultPaymentMethods);
                    setIsFormTouched(true);
                  }}
                />
              </PaymentMethodsBody>
            </PaymentMethodsStyled>)}
          {isFormTouched && <ButtonSubmitPaymentMethods onClick={onSubmitData}>Submit</ButtonSubmitPaymentMethods>}
        </PaymentMethodsContainer>
      )}
    </>
  );
};

interface IHasIssuing {
  userInsurerId?: string,
  isInsurerEnabled?: boolean,
  isIssuingEnabled?: boolean,
  handleChangeIssuing?: (key: string, value: boolean) => void,
}

const HasIssuing = ({
  userInsurerId,
  isInsurerEnabled,
  isIssuingEnabled,
  handleChangeIssuing,
}: IHasIssuing) => (
  <InsurerStyledHeaderInsurerDataItem disabled={!isInsurerEnabled || !userInsurerId} onClick={(isInsurerEnabled && userInsurerId && handleChangeIssuing) ? () => handleChangeIssuing(userInsurerId as string, !isIssuingEnabled) : undefined}>
    <InsurerStyledHeaderInsurerDataItemIcon checked={!!(isInsurerEnabled && isIssuingEnabled)} icon={isIssuingEnabled ? 'check' : 'close'} />
    <InsurerStyledHeaderInsurerDataItemTitle checked={!!(isInsurerEnabled && isIssuingEnabled)}>
      {isIssuingEnabled ? translate({ key: 'master.issuing_enabled' }) : translate({ key: 'master.issuing_disabled' })}
    </InsurerStyledHeaderInsurerDataItemTitle>
  </InsurerStyledHeaderInsurerDataItem>
);

export default Insurer;
