import { TUser } from 'app/Pages/common';
import axios, { AxiosError, AxiosPromise } from 'axios';
import React, { useEffect, useState } from 'react';
import { TError, TFetchStatus } from 'models';
import { concurrentRequestsResolver, preflightAPICall, processAPIError, translate } from 'utils';
import { API_GROUP_LIST_URL } from 'config/api';
import { API_USER_ITEM_URL } from 'app/Pages/User/index';
import { SectionRole, SectionRoleControlsRow, SectionRoleFromRow, SectionRoleSubtitle, SectionRoleTitle } from '../styles';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { Button, Checkbox } from '@insly/qmt-reactjs-ui-lib';
import { renderErrors } from 'app/Components/common/Error';
import { TBrokerGroup } from 'app/Pages/Broker/profile/body/Structure';
import { SelectBranch } from './styles';

type TData = {
  brokerGroupType: string,
  networkName: string,
  agencyName: string,
  agencyList: {
    key: string,
    value: string,
  }[],
  branchList: {
    key: string,
    value: string,
  }[],
  departmentList: {
    key: string,
    value: string,
  }[],
  teamList: {
    key: string,
    value: string,
  }[],
};

type TForm = {
  network: string,
  agency: string,
  branch: string,
  department: string,
  team: string,
  role_id: string,
  selected_department: string,
};

export const UserRole = ({
  profile,
  handlePatchUser,
  updateUserProfile
} : {
  profile: TUser,
  handlePatchUser: (userForm: Partial<TUser>, onError: (error: AxiosError) => void, onSuccess: () => void) => void,
  updateUserProfile: (user: TUser) => void,
}) => {

  const dataTemplate: TData = {
    brokerGroupType: '',
    networkName: '',
    agencyName: '',
    agencyList: [],
    branchList: [],
    departmentList: [],
    teamList: [],
  };

  const formTemplate: TForm = {
    network: '',
    agency: '',
    branch: '',
    department: '',
    team: '',
    role_id: profile.role?.id || '',
    selected_department: profile.role?.group.domain_tag || '',
  };

  const [roles, updateRoles] = useState<{key: string, value: string}[]>([]);

  const [form, updateForm] = useState({
    ...formTemplate
  });
  const [data, updateData] = useState({
    ...dataTemplate
  });
  const [fetchStatus, updateFetchStatus] = useState<TFetchStatus>(null);
  const [errors, updateErrors] = useState<TError[]>([]);
  const [isFormTouched, updateIsFormTouched] = useState(false);

  useEffect(() => {
    getBrokerGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.broker?.id, profile.id]);

  const handleFormChange = (key: string, value: string) => {
    let touchedForm = { ...form };
    let touchedData = { ...data };

    //@ts-ignore
    touchedForm[key] = value;

    switch (key) {
      case 'selected_department':
        if (form.selected_department === value) {
          return;
        }

        touchedForm.role_id = '';
        //@ts-ignore
        getRoles(touchedForm[value], updateRoles, updateErrors, updateFetchStatus);
        break;
      case 'agency':
        touchedForm = {
          ...touchedForm,
          agency: value,
          branch: '',
          department: '',
          team: '',
        };
        touchedData = {
          ...touchedData,
          departmentList: [],
          teamList: [],
        };
        getGroups(value, updateFetchStatus, (groups) => updateData({
          ...touchedData,
          'branchList': groups
        }));
        break;
      case 'branch':
        touchedForm = {
          ...touchedForm,
          branch: value,
          department: '',
          team: '',
        };
        touchedData = {
          ...touchedData,
          teamList: [],
        };
        getGroups(value, updateFetchStatus, (groups) => updateData({
          ...touchedData,
          'departmentList': groups
        }));
        break;
      case 'department':
        touchedForm = {
          ...touchedForm,
          department: value,
          team: '',
        };
        getGroups(value, updateFetchStatus, (groups) => updateData({
          ...touchedData,
          'teamList': groups
        }));
        break;
      case 'team':
        touchedForm.team = value;
        break;
    }

    if (key === touchedForm['selected_department']) {
      touchedForm.role_id = '';
      getRoles(touchedForm[touchedForm['selected_department'] as 'branch' | 'team'], updateRoles, updateErrors, updateFetchStatus);
    }

    if (errors?.length) {
      updateErrors([]);
    }
    updateForm(touchedForm);
    updateIsFormTouched(true);
  };

  const getBrokerGroup = () => {
    updateFetchStatus('loading');

    if (roles?.length) {
      updateRoles([]);
    }
    if (errors?.length) {
      updateErrors([]);
    }
    if (isFormTouched) {
      updateIsFormTouched(false);
    }

    preflightAPICall(() => {
      const fetchedData = {
        ...dataTemplate
      };
      const touchedForm = {
        ...formTemplate
      };

      axios.get(`${API_GROUP_LIST_URL}/${profile.broker?.group_id || ''}`).then(response => {
        const domainTag = response.data.domain_tag;
        fetchedData.brokerGroupType = domainTag;

        switch (domainTag) {
          case 'network':
            touchedForm.network = response.data.id;
            fetchedData.networkName = response.data.name;
            break;
          case 'agency':
            touchedForm.agency = response.data.id;
            fetchedData.agencyName = response.data.name;
            break;
        }

        prepareUserRoleData(fetchedData, touchedForm);

      }).catch(onError);

    });

  };

  const onError = (error: AxiosError) => {
    console.error(error);
    updateFetchStatus('failed');
    updateErrors(() => processAPIError(error, true) as TError[]);
  };

  const prepareUserRoleData = (fetchedData: TData, touchedForm: TForm) => {

    preflightAPICall(() => {
      let apiUrl;

      // Get Data according to Broker Group
      if (fetchedData.brokerGroupType === 'network') {
        //Get Agencies
        apiUrl = `${API_GROUP_LIST_URL}/${touchedForm.network}`;
      } else if (fetchedData.brokerGroupType === 'agency' && !profile.role) {
        //Get Branches
        apiUrl = `${API_GROUP_LIST_URL}/${touchedForm.agency}`;
      }

      if (apiUrl) {
        axios.get(apiUrl).then(response => {
          axios.get(`${API_GROUP_LIST_URL}?parent_id=${response.data?.id || ''}&page_size=1000`).then(listResponse => {
            // @ts-ignore
            const listOptions = prepareGroupListOptions(listResponse.data?.results);

            if (fetchedData.brokerGroupType === 'network') {
              fetchedData.agencyList = listOptions;
            } else if (fetchedData.brokerGroupType === 'agency') {
              fetchedData.branchList = listOptions;
            }

            if (profile.role) {
              getListsByUserRole(touchedForm, fetchedData);
            } else {
              updateFetchStatus('success');
              updateForm(touchedForm);
              updateData(fetchedData);
            }
            return;

          }).catch(onError);
        }).catch(onError);

      } else {
        if (profile.role) {
          getListsByUserRole(touchedForm, fetchedData);
        } else {
          updateForm({ ...formTemplate });
          updateData({ ...dataTemplate });
        }

      }

    });
  };

  const getListsByUserRole = (touchedForm: TForm, fetchedData: TData) => {
    const roleGroup = profile.role?.group as {domain_tag: string, name: string, id: string, parent_id: string};

    getRoles(roleGroup.id, updateRoles, updateErrors);

    const dataRequests: AxiosPromise[] = [];
    const requestErrors: TError[] = [];

    switch (profile.role?.group.domain_tag) {

      case 'agency':
        touchedForm.agency = profile.role?.group.id;
        axios.get(`${API_GROUP_LIST_URL}?parent_id=${profile.role?.group.id || ''}&page_size=1000`).then(branchesListResponse => {
          // @ts-ignore
          fetchedData.branchList = prepareGroupListOptions(branchesListResponse.data?.results);
          updateFetchStatus('success');
          updateForm(touchedForm);
          updateData(fetchedData);
        }).catch(onError);
        break;

      case 'branch':
        touchedForm.branch = profile.role?.group.id;
        touchedForm.agency = profile.role?.group.parent_id;

        dataRequests.push(
          //Get Departments
          axios.get(`${API_GROUP_LIST_URL}?parent_id=${roleGroup.id || ''}&page_size=1000`),
          // Get Branches
          axios.get(`${API_GROUP_LIST_URL}/${roleGroup.id}`)
        );

        axios.all(concurrentRequestsResolver(dataRequests)).then(axios.spread((...responses) => {

          responses.forEach((response, index) => {
            // @ts-ignore need to investigate how to handle such different types of response
            if (response.error) {
              const item = response as { error: Error };
              requestErrors.push(...processAPIError(item.error as AxiosError) as TError[]);

            } else {
              switch (index) {
                case 0:
                  // @ts-ignore
                  fetchedData.departmentList = prepareGroupListOptions(response.data?.results);
                  break;
                case 1:
                  // Get Branches List
                  // @ts-ignore
                  axios.get(`${API_GROUP_LIST_URL}?parent_id=${response.data?.parent_id || ''}&page_size=1000`).then(branchesListResponse => {
                    // @ts-ignore
                    fetchedData.branchList = prepareGroupListOptions(branchesListResponse.data?.results);
                    updateFetchStatus('success');
                    updateForm(touchedForm);
                    updateData(fetchedData);
                  }).catch(onError);
              }
            }
          });

        }));
        break;

      case 'department':
        //Get Departments + Teams
        touchedForm.department = profile.role?.group.id;
        touchedForm.branch = profile.role?.group.parent_id;

        dataRequests.push(
          //Get Departments
          axios.get(`${API_GROUP_LIST_URL}?parent_id=${roleGroup.parent_id || ''}&page_size=1000`),
          //Get Teams
          axios.get(`${API_GROUP_LIST_URL}?parent_id=${roleGroup.id || ''}&page_size=1000`),
          // Get Branches
          axios.get(`${API_GROUP_LIST_URL}/${roleGroup.parent_id}`)
        );

        axios.all(concurrentRequestsResolver(dataRequests)).then(axios.spread((...responses) => {

          responses.forEach((response, index) => {
            // @ts-ignore need to investigate how to handle such different types of response
            if (response.error) {
              const item = response as { error: Error };
              requestErrors.push(...processAPIError(item.error as AxiosError) as TError[]);

            } else {
              switch (index) {
                case 0:
                  // @ts-ignore
                  fetchedData.departmentList = prepareGroupListOptions(response.data?.results);
                  break;
                case 1:
                  // @ts-ignore
                  fetchedData.teamList = prepareGroupListOptions(response.data?.results);
                  break;
                case 2:
                  // @ts-ignore
                  // Get Branches List
                  // @ts-ignore
                  axios.get(`${API_GROUP_LIST_URL}?parent_id=${response.data?.parent_id || ''}&page_size=1000`).then(branchesListResponse => {
                    // @ts-ignore
                    fetchedData.branchList = prepareGroupListOptions(branchesListResponse.data?.results);
                    updateFetchStatus('success');
                    updateForm(touchedForm);
                    updateData(fetchedData);
                  }).catch(onError);
                  break;
              }
            }
          });

        }));
        break;

      case 'team':
        //Get Teams + Departments + Branches

        touchedForm.team = profile.role?.group.id;
        touchedForm.department = profile.role?.group.parent_id;

        dataRequests.push(
          //Get Teams
          axios.get(`${API_GROUP_LIST_URL}?parent_id=${roleGroup.parent_id || ''}&page_size=1000`),
          //Get Department Group + Departments + Branches
          axios.get(`${API_GROUP_LIST_URL}/${roleGroup.parent_id}`)
        );

        axios.all(concurrentRequestsResolver(dataRequests)).then(axios.spread((...responses) => {

          responses.forEach((response, index) => {
            // @ts-ignore need to investigate how to handle such different types of response
            if (response.error) {
              const item = response as { error: Error };
              requestErrors.push(...processAPIError(item.error as AxiosError) as TError[]);

            } else {
              switch (index) {
                case 0:
                  // @ts-ignore
                  fetchedData.teamList = prepareGroupListOptions(response.data?.results);
                  break;
                case 1:
                  // Get Departments List + Branch List
                  // @ts-ignore
                  axios.get(`${API_GROUP_LIST_URL}?parent_id=${response.data?.parent_id || ''}&page_size=1000`).then(departmentsListResponse => {
                    // @ts-ignore
                    fetchedData.departmentList = prepareGroupListOptions(departmentsListResponse.data?.results);
                    // @ts-ignore
                    touchedForm.branch = response.data?.parent_id;

                    axios.get(`${API_GROUP_LIST_URL}/${touchedForm.branch}`).then(branchGroupResponse => {
                      touchedForm.agency = branchGroupResponse.data?.parent_id;
                      axios.get(`${API_GROUP_LIST_URL}?parent_id=${branchGroupResponse.data?.parent_id || ''}&page_size=1000`).then(branchListResponse => {
                        fetchedData.branchList = prepareGroupListOptions(branchListResponse.data?.results);
                        updateFetchStatus('success');
                        updateForm(touchedForm);
                        updateData(fetchedData);

                      }).catch(onError);
                    }).catch(onError);
                  }).catch(onError);
              }
            }
          });

        }));

    }

    if (requestErrors.length) {
      updateErrors(requestErrors);
      updateFetchStatus('failed');
      return;
    }

  };

  const validate = () => {
    if (!form.role_id) {
      updateErrors([
        {
          code: '',
          message: 'Please, select User Role'
        }
      ]);
      return false;
    }

    return true;
  };

  const updateUserRole = () => {
    if (!validate()) {
      return;
    }

    preflightAPICall(() => {
      updateFetchStatus('loading');
      handlePatchUser({
        role_id: form.role_id
      }, (error) => {
        updateErrors(() => processAPIError(error, true) as TError[]);
        updateFetchStatus('failed');
      }, () => {
        //@TODO: remove this redundant call when response with old role issue will be fixed in API
        preflightAPICall(() => {
          axios.get(API_USER_ITEM_URL.replace('%USER_ID%', profile.id as string)).then(response => {
            const data = response.data;
            updateUserProfile(data);
            updateFetchStatus('success');
            updateIsFormTouched(false);
          }).catch(error => {
            updateFetchStatus('failed');
            updateErrors(() => processAPIError(error, true) as TError[]);
          });
        });

      });
    });
  };

  return (
    <SectionRole>
      {fetchStatus === 'loading' ? <LoadingOverlay /> : null}
      <SectionRoleTitle>{translate({ key: 'master.user.role' })}</SectionRoleTitle>
      {data.brokerGroupType === 'network' ? (
        <SectionRoleFromRow>
          <SectionRoleSubtitle>Network: {data.networkName}</SectionRoleSubtitle>
          <Checkbox
            label={translate({ key: 'master.broker_group.department.assign' })}
            checked={form.selected_department === 'network'}
            handleChange={() => handleFormChange('selected_department', 'network')}
          />
        </SectionRoleFromRow>
      ) : null}
      <SectionRoleFromRow>
        {data.brokerGroupType === 'agency' ? (
          <SectionRoleSubtitle>{translate({ key: 'master.broker_group.agency.title' })}: {data.agencyName}</SectionRoleSubtitle>
        ) : (
          <SelectBranch
            label={translate({ key: 'master.user.agency' })}
            name="agency"
            disabled={!data.agencyList?.length}
            options={data.agencyList}
            value={form.agency}
            handleChange={(key, value) => handleFormChange(key as string, value as string)}
          />
        )}
        <Checkbox
          label={translate({ key: 'master.broker_group.assign_agency' })}
          checked={form.selected_department === 'agency'}
          handleChange={() => handleFormChange('selected_department', 'agency')}
        />
      </SectionRoleFromRow>

      <BranchRow
        branchType="branch"
        value={form.branch}
        selectedDepartment={form.selected_department}
        list={data.branchList}
        handleFormChange={handleFormChange}
      />

      <BranchRow
        branchType="department"
        value={form.department}
        selectedDepartment={form.selected_department}
        list={data.departmentList}
        handleFormChange={handleFormChange}
      />

      <BranchRow
        branchType="team"
        value={form.team}
        selectedDepartment={form.selected_department}
        list={data.teamList}
        handleFormChange={handleFormChange}
      />

      <SectionRoleFromRow>
        <SelectBranch
          label={translate({ key: 'master.broker_group.role.title' })}
          name="role_id"
          placeholder={translate({ key: 'common.select.placeholder' })}
          options={roles}
          value={form.role_id}
          handleChange={(key, value) => handleFormChange(key as string, value as string)}
        />
      </SectionRoleFromRow>

      {isFormTouched ? (
        <SectionRoleControlsRow>
          <Button
            preset="secondary"
            onClick={() => {
              getBrokerGroup();
              updateIsFormTouched(false);
              updateErrors([]);
            }}
          >
            {translate({ key: 'common.button.cancel' })}
          </Button>
          <Button
            onClick={() => {
              updateUserRole();
            }}
          >
            {translate({ key: 'common.button.save' })}
          </Button>
        </SectionRoleControlsRow>
      ) : null}
      {errors.length ? renderErrors(errors) : null}
    </SectionRole>
  );
};


const prepareGroupListOptions = (list: TBrokerGroup[]) => {
  if (!list?.length) {
    return [];
  } else {
    return list.map((item) => ({
      key: item.id as string,
      value: item.title || item.name
    }));
  }
};

const getGroups = (groupId: string, updateFetchStatus: (status: TFetchStatus) => void, updateGroups: (groups: {key: string, value: string}[]) => void) => {
  updateFetchStatus('loading');
  preflightAPICall(() => {
    axios.get(`${API_GROUP_LIST_URL}?parent_id=${groupId}&page_size=1000`).then(response => {
      if (response.data?.results?.length) {
        updateGroups(response.data.results.map((item: {id: string, title: string, name: string}) => ({
          key: item.id,
          value: item.title || item.name
        })));
      } else {
        updateGroups([]);
      }
      updateFetchStatus('success');
    }).catch(error => {
      console.error(error);
      updateFetchStatus('failed');
    });
  });

};

const getRoles = (groupId: string, updateRoles: (roles: {key: string, value: string}[]) => void, updateErrors: (errors: TError[]) => void, updateFetchStatus?: (status: TFetchStatus) => void) => {

  if (!groupId) {
    updateErrors([{
      code: '',
      message: 'Group id is empty',
    }]);
    if (updateFetchStatus) {
      updateFetchStatus('failed');
    }
  }

  if (updateFetchStatus) {
    updateFetchStatus('loading');
  }

  preflightAPICall(() => {
    axios.get(`${API_GROUP_LIST_URL}/${groupId}/roles`).then(response => {
      if (response.data?.length) {
        updateRoles(response.data.map((item: {id: string, name: string}) => ({
          key: item.id,
          value: item.name
        })));
      } else {
        updateRoles([]);
      }
      if (updateFetchStatus) {
        updateFetchStatus('success');
      }
    }).catch(error => {
      updateErrors(processAPIError(error, true) as TError[]);
      if (updateFetchStatus) {
        updateFetchStatus('failed');
      }
    });
  });

};

const BranchRow = ({
  branchType,
  list,
  value,
  selectedDepartment,
  handleFormChange,
} : {
  branchType: string,
  list: {key: string, value: string}[],
  value: string,
  selectedDepartment: string,
  handleFormChange: (key: string, value: string) => void,
}) => (
  <SectionRoleFromRow>
    <SelectBranch
      label={translate({ key: `master.broker_group.${branchType}.title` })}
      name={branchType}
      placeholder={translate({ key: 'common.select.placeholder' })}
      disabled={!list?.length}
      options={list}
      value={value}
      handleChange={(key, value) => handleFormChange(key as string, value as string)}
    />
    <Checkbox
      disabled={!value}
      label={translate({ key: `master.broker_group.assign_${branchType}` })}
      checked={selectedDepartment === branchType}
      handleChange={() => handleFormChange('selected_department', branchType)}
    />
  </SectionRoleFromRow>
);
