import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';
import { disabledStyles } from 'styles/helpers';
import { Button } from '@insly/qmt-reactjs-ui-lib';

export const InsurerStyled = styled.div`
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const InsurerStyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const InsurerStyledHeaderSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 24px;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.strokeB};
  }
`;

export const InsurerStyledHeaderInsurerDataItem = styled.li<{ disabled: boolean }>`
  ${disabledStyles};
  display: flex;
  align-items: center;
  padding: 4px 16px;
`;

export const InsurerStyledHeaderInsurerDataItemIcon = styled(DefaultIcon)<{ checked: boolean }>`
  width: 12px;
  height: 12px;
  margin-right: 16px;
  fill: ${({ checked, theme }) => checked ? '#00AF87' : theme.colors.error };
  stroke: ${({ checked, theme }) => checked ? '#00AF87' : theme.colors.error };
`;

export const InsurerStyledHeaderInsurerDataItemTitle = styled.div<{ checked: boolean }>`
  ${({ theme }) => theme.text.small};
  color: ${({ checked, theme }) => checked ? '#00AF87' : theme.colors.error };
`;

export const InsurerStyledHeaderTitle = styled.h3`
  flex: 1;
  ${({ theme }) => theme.text.headerH3};
`;

export const PaymentMethodsTitleContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) => disabled && theme.colors.textOff };
  pointer-events: ${({ disabled }) => disabled && 'none' };
  cursor: pointer;
`;

export const PaymentMethodsStyledTitle = styled.div`
  ${({ theme }) => theme.text.small};
  margin-left: 1rem;
`;

export const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const PaymentMethodsBody = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
`;

export const PaymentMethodsTitle = styled.div`
  text-transform: capitalize;
  ${({ theme }) => theme.text.normal};
`;

export const ButtonSubmitPaymentMethods = styled(Button)`
  margin-top: 0.5rem;
`;

export const PaymentMethodsStyled = styled.div`
  padding: 0.25rem 0;
`;
