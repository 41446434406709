import styled from 'styled-components';
import { DefaultIcon } from 'styles/common';

export const DrawerControlsRow = styled.div`
  display: flex;
  padding: 20px 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
`;

export const DrawerControlsRowControl = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DrawerControlsRowControlIcon = styled(DefaultIcon)<{ margin?: string }>`
  margin-right: ${({ margin }) => margin === 'right' ? '16px' : null};
  margin-left: ${({ margin }) => margin === 'left' ? '16px': null};
  fill: ${({ theme }) => theme.colors.black};
  stroke: ${({ theme }) => theme.colors.black};
`;

export const DrawerControlsRowControlLabel = styled.span`
  ${({ theme }) => theme.text.small};
`;
