import React, { useEffect, useState } from 'react';
import { BodySSO } from './body/SSO';
// import HeaderOut from 'app/Components/Header/Out';
// import FooterOut from 'app/Components/Footer/Out';
import { PageAuthStyled, Content } from './styles';
import { useParams } from 'react-router-dom';
import bgDefault from 'assets/images/bg_logo.svg';

export type TStep = 'login' | 'logout';

export interface IPageAuth {
  backgroundImageUrl?: string,
}

//@TODO: Uncomment all commented and update when old Insly 3 login will be replaces with QMT one
export const PageAuth = ({ backgroundImageUrl = bgDefault }: IPageAuth) => {
  const [step, updateStep] = useState<TStep>('login');
  const { section } = useParams<{ section: TStep }>();

  // let history = useHistory();

  useEffect(() => {
    if (section !== step) {
      updateStep(section);
    }
  }, [section, step]);


  // const handleUpdateStep = (step: TStep) => {
  //   history.push(`/auth/${step}`);
  // };

  return (
    <PageAuthStyled>
      {/*<HeaderOut />*/}
      <Content
        className="content"
        // style={{
        //   background: `url(${backgroundImageUrl}) 25px center / auto 80% no-repeat`,
        // }}
      >
        {renderBody()}
      </Content>
      {/*{window._env_.TEST_TEXT}*/}
      {/*<FooterOut />*/}
    </PageAuthStyled>
  );
};

const renderBody = () => (
  <BodySSO />
);

export default PageAuth;

