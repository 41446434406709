import React from 'react';
import { prepareBrokerOptions, TTableData } from '../index';
import { TTableData as TBrokerTableData } from 'app/Pages/Broker';
import { Tooltip } from '@insly/qmt-reactjs-ui-lib';
import Pagination from 'app/Components/common/Pagination';
import { DefaultIcon } from 'styles/common';
import { TStandardSectionStep } from 'models';
import { List, ListInner, TitleWrapper, Title, TableStyled } from 'app/Pages/common/List/styles';
import { TUser } from 'app/Pages/common';
import { SelectBroker } from './styles';
import { translate } from 'utils';

interface IProps {
  step: TStandardSectionStep,
  title: string,
  dataStatus: string,
  noResultsText: string,
  userList: TTableData,
  brokerList: TBrokerTableData,
  updateSelectedUser: (user: TUser) => void,
  selectedUser: TUser | null,
  drawerType: string,
  filterBroker?: string,
  handleListChangePage: (page: number) => void,
  handleSearchChange?: (value: string) => void,
  handleAddUserClick: () => void,
  filterByBroker: (value: string) => void,
}

export const UserList = ({
  step,
  title,
  dataStatus,
  noResultsText,
  userList,
  brokerList,
  updateSelectedUser,
  selectedUser,
  drawerType,
  filterBroker,
  handleListChangePage,
  handleSearchChange,
  handleAddUserClick,
  filterByBroker,
}: IProps) => (
  <List step={step}>
    <ListInner>
      <TitleWrapper>
        <Title>{title}</Title>
        <Tooltip align="top" message={translate({ key: 'master.user.add' })}>
          <DefaultIcon icon="add_user" onClick={handleAddUserClick} />
        </Tooltip>
      </TitleWrapper>
      <TableStyled
        dataStatus={dataStatus}
        data={userList.results ? userList.results as never[] : undefined}
        noResultsText={noResultsText || 'No results'}
        onClickRow={drawerType === 'profile' ? (index, data: TUser) => updateSelectedUser(data) : () => {}}
        selectedId={selectedUser ? selectedUser.id : undefined}
        handleSearchChange={handleSearchChange}
        controlsRight={[renderBrokerSelect(brokerList, filterBroker, filterByBroker)]}
        columns={[
          {
            name: 'name',
            onRender: renderNameCol,
            styles: {
              flex: 1
            }
          },
          {
            name: 'email',
            onRender: renderEmailCol,
            styles: {
              flex: 1
            }
          },
          {
            name: 'broker',
            onRender: renderBrokerCol,
            styles: {
              flex: 1
            }
          },
          {
            name: 'status',
            onRender: renderStatusCol,
          },
          {
            name: 'main_account',
            onRender: renderMainAccountCol,
            styles: {
              paddingLeft: '10px',
              width: '26px'
            }
          },
        ]}
      />
      {userList?.last_page && userList.last_page > 1 ? Pagination({ page: userList.page as number, last_page: userList.last_page }, handleListChangePage) : null}
    </ListInner>
  </List>
);

export const renderNameCol = (row: TUser) => <>{row.props.name}</>;

export const renderEmailCol = (row: TUser) => <>{row.props.email}</>;

export const renderStatusCol = (row: TUser) => <>{translate({ key: `customer.status.${row.status == '1' ? 'active' : 'inactive'}` })}</>;

const renderMainAccountCol = (row: TUser) => <>{row.props.main_account ? <DefaultIcon icon="superuser" /> : ''}</>;

const renderBrokerCol = (row: TUser) => <>{row.broker?.name}</>;

const renderBrokerSelect = (brokerList: TBrokerTableData, filterBroker: string = '', filterByBroker: (value: string) => void) => {
  const SELECT_BROKER_ALL_TEXT = translate({ key: 'master.broker' });

  return (
    <SelectBroker
      key="broker-select"
      name="broker"
      value={filterBroker}
      placeholder={SELECT_BROKER_ALL_TEXT}
      searchable={true}
      options={[
        {
          key: '',
          value: SELECT_BROKER_ALL_TEXT
        },
        ...prepareBrokerOptions(brokerList)
      ]}
      handleChange={(name, value) => {
        filterByBroker(value as string);
      }}
    />
  );
};
