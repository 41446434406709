import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { TTableData as TBrokerTableData } from 'app/Pages/Broker';
import { shouldShowMoreShow, ShowMore } from 'app/Components/common/ShowMore';
import {
  DrawerCloseIcon,
  DrawerDataForm,
  DrawerHeaderRow,
  DrawerHeaderRowTitle,
} from 'app/Components/common/Drawer/styles';
import { JSONSchemaProp, TError, TStandardSectionStep, TUserStatus } from 'models';
import { TUser } from 'app/Pages/common';
import { renderErrors } from 'app/Components/common/Error';
import { Loader } from 'styles/common';
import ControlsRow from 'app/Components/common/DrawerHeaderControlsRow';
import DropdownSingle from 'app/Components/common/DropdownSingle';
import {
  BodySection,
  ButtonMask,
  DrawerBodyAdmin,
  UserRoleIcon,
  UserRoleLabel,
  UserRoleStyled,
  UserRoleTitle
} from '../styles';
import { TUserForm } from 'app/Pages/User';
import { processAPIError, filterUncommonErrors, translate } from 'utils';
import LoadingOverlay from 'helpers/LoadingOverlay';
import { renderControls, scrollElementToBottom, scrollElementToTop } from 'app/Components/common/Drawer';
import { useApp } from '../../../../../context/App';

const FAKE_USER_STATUS_PROP = {
  'enum':['1', '2'],
  'type':'string',
  'title':'Customer status',
  'searchable':true,
  'description':'Customer status',
  'enum_translation_keys':{ '1':'customer.status.active', '2':'customer.status.inactive' },
  'title_translation_key':'customer.status'
};

interface IProps {
  step: TStandardSectionStep,
  selectedUser: TUser | null,
  userId?: string,
  brokerList: TBrokerTableData,
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handlePatchUser: (user: TUser, onError?: (error: AxiosError) => void, onSuccess?: () => void) => void,
  handlePatchUserStatus: (status: TUserStatus, onError?: (error: AxiosError) => void, onSuccess?: () => void) => void,
  handleMaskUser: (userId: string, onError?: (error: AxiosError) => void, onSuccess?: () => void) => void,
  handleDeleteUser: () => void,
  updateSelectedUser: (user: TUser) => void,
  dataStatus: string,
  errors: TError[],
  isMasking: boolean,
}

//@TODO: Update Drawer Lib component and make children not required and remove unnecessary <div />
const DrawerProfile = ({
  selectedUser,
  brokerList,
  handleDrawerClose,
  updateStep,
  handlePatchUser,
  handlePatchUserStatus,
  handleMaskUser,
  handleDeleteUser,
  step,
  dataStatus,
  errors,
  isMasking
}: IProps) => {

  const { showDialog } = useApp();

  const onChangeStatus = (status: string, onError: (error: AxiosError) => void) => {
    if (selectedUser) {
      showDialog({
        body: translate({ key: 'master.dialog.user.update_status' }),
        buttonAccept: translate({ key: 'master.dialog.button.update_user_status' }),
        buttonDecline: translate({ key: 'common.button.cancel' }),
        onAccept: () => handlePatchUserStatus(status as TUserStatus, onError),
      });
    }
  };

  switch (dataStatus) {
    case 'error':
      return (
        <>
          {renderErrors(errors)}
        </>
      );
    case 'success':
      return (selectedUser ? renderDrawerInner(selectedUser, brokerList, isMasking, handleDrawerClose, updateStep, handlePatchUser, handleDeleteUser, handleMaskUser, onChangeStatus, step) : <div />);
    case 'loading':
    default:
      return <Loader />;
  }
};

const renderDrawerInner = (
  selectedUser: TUser,
  brokerList: TBrokerTableData,
  isMasking: boolean,
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handlePatchUser: (selectedUser: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  handleDeleteUser: (userId: string, onError: (error: AxiosError) => void) => void,
  handleMaskUser: (userId: string, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  onChangeStatus: (status: string, onError: (error: AxiosError) => void) => void,
  step: TStandardSectionStep
) => (
  <>
    {HeaderRow(selectedUser, handleDrawerClose)}
    <ControlsRow
      step={step}
      updateStep={(step: string) => updateStep(`user/${step}`)}
      onUserDelete={handleDeleteUser}
      userId={selectedUser.id as string}
      userAdditionalControl={
        <DropdownSingle
          vPadding={'0'}
          hPadding={'0'}
          schemaProp={FAKE_USER_STATUS_PROP as JSONSchemaProp}
          value={selectedUser.status as TUserStatus}
          onChangeValue={onChangeStatus}
          title={translate({ key: 'master.user.status' })}
        />
      }
    />
    <DrawerBody
      step={step}
      userData={selectedUser}
      handlePatchUser={handlePatchUser}
      isMasking={isMasking}
      handleMaskUser={handleMaskUser}
    />
  </>
);

const DrawerBody = ({
  step,
  userData,
  isMasking,
  handlePatchUser,
  handleMaskUser,
}: {
  userData: TUser,
  isMasking: boolean,
  handlePatchUser: (selectedUser: TUser, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  handleMaskUser: (userId: string, onError?: (errors: AxiosError) => void, onSuccess?: () => void) => void,
  step: TStandardSectionStep,
}) => {
  const bodyEl = useRef<HTMLDivElement>(null);
  const [showMore, updateShowMore] = useState(false);

  useEffect(() => {
    if (bodyEl.current !== null) {
      scrollElementToTop(bodyEl.current);
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const handleSaveUser = (userForm: TUser, onError?: (error: AxiosError) => void, onSuccess?: () => void) => {
    const requestObject: TUser = {
      ...userData,
      ...userForm
    };

    handlePatchUser(requestObject, onError, onSuccess);
  };

  const onBodyScroll = () => {
    if (bodyEl.current !== null) {
      shouldShowMoreShow(bodyEl.current, showMore, updateShowMore);
    }
  };

  return (
    <DrawerBodyAdmin ref={bodyEl} onScroll={onBodyScroll} showMore={step === 'list'}>
      <UserForm
        isMasking={isMasking}
        userData={userData}
        handlePatchUser={handleSaveUser}
        handleMaskUser={handleMaskUser}
      />
      {step === 'list' ? <ShowMore show={showMore} onClick={() => scrollElementToBottom(bodyEl.current)} /> : null}
    </DrawerBodyAdmin>
  );
};

const UserForm = ({
  isMasking,
  userData,
  handlePatchUser,
  handleMaskUser,
}: {
  isMasking: boolean,
  userData: TUser,
  handlePatchUser: (userForm: TUser, onError: (error: AxiosError) => void, onSuccess: () => void) => void,
  handleMaskUser: (userId: string, onError: (error: AxiosError) => void, onSuccess: () => void) => void,
}) => {

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  const [form, updateForm] = useState<TUserForm>(fillForm(userData));

  const [errors, updateErrors] = useState<TError[]>([]);
  const [isTouched, updateIsTouched] = useState(false);

  const [isSubmitting, updateIsSubmitting] = useState(false);

  const resetForm = () => {
    updateForm(fillForm(userData));
    updateErrors([]);
    updateIsTouched(false);
  };

  const handleSaveData = () => {
    updateIsSubmitting(true);

    const patchUserData = {
      id: userData.id,
      broker_id: form.broker_id,
      props: {
        name: form.name,
        email: form.email,
        role: form.role,
        communication_language: form.communication_language,
      },
      status: userData.status,
    };

    handlePatchUser(patchUserData, (error) => {
      updateErrors(() => processAPIError(error, true) as TError[]);
      updateIsSubmitting(false);
    }, () => {
      updateIsTouched(false);
      updateIsSubmitting(false);
    });
    updateIsTouched(false);
  };

  return (
    <DrawerDataForm>
      {isSubmitting ? <LoadingOverlay /> : null}
      <UserRole
        userData={userData}
      />
      <ButtonMask onClick={() => handleMaskUser(userData.id as string, (error) => {
        updateErrors(() => processAPIError(error, true) as TError[]);
        updateIsSubmitting(false);
      }, () => {
        updateIsTouched(false);
        updateIsSubmitting(false);
        window.open(window._env_.QMT_APP_URL, '_blank');
      })}
      disabled={isMasking}
      isMasking={isMasking}
      >
        {isMasking ? <Loader /> : translate({ key: 'master.mask_user' })}
      </ButtonMask>
      {errors?.length ? renderErrors(filterUncommonErrors(errors)) : null}
      {(isTouched) ? renderControls('list', isTouched, handleSaveData, resetForm) : null}
    </DrawerDataForm>
  );

};

const UserRole = ({
  userData
} : {
  userData: TUser,
}) => (
  <BodySection>
    <UserRoleStyled>
      <UserRoleIcon icon="user" />
      <div>
        <UserRoleLabel>{translate({ key: 'master.user.role' })}</UserRoleLabel>
        {renderUserRoleTitle(userData)}
      </div>
    </UserRoleStyled>
  </BodySection>
);

const HeaderRow = (userData: TUser, handleDrawerClose: () => void) => (
  <DrawerHeaderRow>
    <DrawerHeaderRowTitle>{userData.props.name}</DrawerHeaderRowTitle>
    <DrawerCloseIcon icon="close" onClick={handleDrawerClose} />
  </DrawerHeaderRow>
);

const fillForm = (userData: TUser) => ({
  name: userData.props.name,
  email: userData.props.email,
  broker_id: userData.broker_id,
  role: userData.props.role,
  communication_language: userData.props.communication_language || []
});

const renderUserRoleTitle = (userData: TUser) => (
  <UserRoleTitle>
    {userData.role ?
      `${userData.role?.name} w ${userData.broker?.name || userData.broker?.tag} (${translate({ key: `master.broker_group.${userData.role?.group.domain_tag}.title` })}: ${userData.role?.group.title || userData.role?.group.name})`
      : `${userData.broker?.name || userData.broker?.tag} (${translate({ key: 'master.user.no_role' })})`}

  </UserRoleTitle>
);

export default DrawerProfile;
